import React, { FC, useRef, useContext, useState } from "react";
import "./style.scss";
// import contacto from "../../../assets/img/hb/bg-contacto.jpg";
import bgImage from "../../../assets/img/hb/bg-contacto.jpg";
import bannerContacto from "../../../assets/img/hb/bannerContactoPSHB.png";
import ReCAPTCHA from "react-google-recaptcha";
import { validateModelFacturacion } from "./validationSchema";
import { Formik } from "formik";
import { fetchExternal } from "../../../utils/fetch";
import Swal from "sweetalert2";
import { useToggle } from "../../../utils/hooks/useToggle";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import { BASE_URL_STRAPPI } from "../../../utils/constants";
import { PoliticaProteccionComponent } from "../../../components/common/footer/politica-proteccion";
import useProgressiveImage from "../../../hooks/useProgressiveImage";
import areas from "../../../assets/data/areas.json";
import { SwalMessage } from "../../../utils/sweet-alert/sweet-alert";

const AtencionAlPacienteComponent = () => {
  const loadedBgImage = useProgressiveImage(bgImage);
  const captcha = useRef(null);
  const politicaToggle = useToggle();
  const { sitePSHB, user, setUser, footerConfiguration } = useContext(
    UserGlobalContextMemorySpace
  );
  const [temporalUser, setTemporalUser] = React.useState(
    JSON.parse(JSON.stringify(user))
  );
  const politicaContactoToggle = useToggle();
  const aceptoPrivacidadRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    nombre: user.user.data.Customers.firstName,
    apellido: user.user.data.Customers.lastName,
    email: user.user.data.Customers.email,
    celular: temporalUser.user.data.Customers.mobile,
    dni: temporalUser.user.data.Customers.dni,
    localidad: "",
    opcion: "",
    subOpcion: "",
    comentario: "",
    aceptoPrivacidad: false,
    validateAceptoPrivacidad: "",
  };

  const changeValue = (e, setFieldValue) => {
    if (e.target.name === "celular") {
      const { name, value } = e.target;
      const regex = /^[0-9]$/;

      if (value.length <= 12) {
        // Validar que no haya más de 12 caracteres
        if (regex.test(value[value.length - 1]) || value.length == 0) {
          setFieldValue(name, value);
        }
      }
    }

    if (e.target.name === "localidad") {
      const { name, value } = e.target;
      const regex = /^[a-zA-Z0-9\sáéíóúÁÉÍÓÚüÜñÑ]+$/; // Expresión regular para letras de la A a la Z (mayúsculas o minúsculas), números y espacios en blanco

      if (value.length <= 1 || value.length <= 50) {
        // Validar que no haya más de 50 caracteres
        if (regex.test(value)) {
          setFieldValue(name, value);
        } else {
          setFieldValue(name, "");
        }
      }
    }
  };

  const handleChangeCel = (e, setFieldValue) => {
    const { value } = e.target;
    const onlyNumbers = /^[0-9]+$/; // Expresión regular para validar números
    if (value === "" || (onlyNumbers.test(value) && value.length <= 12)) {
      setFieldValue(e.target.name, value);
    }
  };

  const onSubmit = async (values, resetForm) => {
    setLoading(true);
    if (captcha.current?.getValue()) {
      let area = areas.find((area) => area.Campo === values.subOpcion);

      const correo = {
        to:
          process.env.REACT_APP_ENVIROMENT_DEBUG === "true"
            ? process.env.REACT_APP_MAIL_DEBUG
            : area.Mail,
        subject:
          (process.env.REACT_APP_ENVIROMENT_DEBUG === "true" ? "QA-" : "") +
          "Solicitud de contacto - " +
          values.opcion +
          " - " +
          values.subOpcion,
        text: `<b>Nombre:</b> ${values.nombre} <br/>
        <b>Apellido:</b> ${values.apellido} <br/>
        <b>Email:</b> ${values.email} <br/>
        <b>DNI:</b> ${values.dni} <br/>
        <b>Celular:</b> ${values.celular} <br/>        
        <b>Localidad:</b> ${values.localidad} <br/>        
        <b>Motivo:</b> ${values.opcion} <br/>        
        <b>Mensaje:</b> ${values.comentario} <br/>        
        `,
      };

      const { data } = await fetchExternal(
        BASE_URL_STRAPPI + "/email",
        "post",
        correo
      );
      resetForm();
      aceptoPrivacidadRef.current.checked = false;
      captcha.current?.reset();

      SwalMessage("success", "", "¡Envío exitoso!", "Aceptar");
    } else {
      Swal.fire("", "Debe indicar que no es un robot", "error");
    }

    setLoading(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validateModelFacturacion}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        onSubmit(values, resetForm);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        /* and other goodies */
      }) => (
        <section>
          <div className="container p-3">
            <section className="section_contacto_pshb pb-5">
              <div
                className="container-fluid p-3 container_mobiles"
                style={{ marginTop: "3rem" }}
              >
                {/* BANNER SOLO EN MOBILE */}
                <div className="row col-12" style={{ paddingLeft: 0 }}>
                  <a
                    href="https://plandesalud.hospitalbritanico.org.ar/dejanos-tu-consulta"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={bannerContacto}
                      className="img-card-mobile pb-3"
                      style={{
                        height: "100%",
                        width: "104%",
                        objectFit: "cover",
                      }}
                      alt="banner-contacto"
                    />
                  </a>
                </div>
                {/* TITULO */}
                <div className="row pb-1">
                  <div className="col-lg-12">
                    <h2 className="section_contacto_titulo-hb title_mobiles">
                      <b className="section_contacto_titulo-hb">
                        Atención al paciente
                      </b>
                    </h2>

                    <p className="subtitulo-hb">
                      Envianos tu consulta, comentario o sugerencia completando
                      el siguiente formulario.
                    </p>
                  </div>
                </div>
                <div className="separador-hb separador-margin"></div>

                {/* FORM */}
                <div className="">
                  <div className="row g-0">
                    {/* FORMULARIO */}
                    <div
                      className="col-12 col-lg-6 container-dejanos-consulta columna-contenido"
                      id="form-contacto-hb"
                    >
                      <form className="section_contacto_form">
                        <h2 className="section_contacto_form_titulo mb-4 mt-4">
                          <b className="title-hb">Dejanos tu consulta</b>
                        </h2>
                        <div className="mb-4 row align-items-center">
                          <div className="col-lg-2 col-md-3">
                            <label className="input-title-hb">Nombre</label>
                          </div>
                          <div className="col-lg-10 col-md-9">
                            <input
                              readOnly
                              type="text="
                              name="nombre"
                              value={values.nombre}
                              className="form-control b-radius-20 shadow-blue"
                            />
                            {errors.nombre && touched.nombre && (
                              <div className="text-danger">
                                {errors?.nombre}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mb-4 row align-items-center">
                          <div className="col-lg-2 col-md-3">
                            <label className="input-title-hb">Apellido</label>
                          </div>
                          <div className="col-lg-10 col-md-9">
                            <input
                              readOnly
                              type="text="
                              name="apellido"
                              value={values.apellido}
                              className="form-control b-radius-20 shadow-blue"
                            />
                            {errors.apellido && touched.apellido && (
                              <div className="text-danger">
                                {errors?.apellido}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mb-4 row align-items-center">
                          <div className="col-lg-2 col-md-3">
                            <label className="input-title-hb">E-mail</label>
                          </div>
                          <div className="col-lg-10 col-md-9">
                            <input
                              readOnly
                              type="text="
                              name="email"
                              value={values.email}
                              className="form-control b-radius-20 shadow-blue"
                            />
                            {errors.email && touched.email && (
                              <div className="text-danger">{errors?.email}</div>
                            )}
                          </div>
                        </div>

                        <div className="mb-4 row align-items-center">
                          <div className="col-lg-2 col-md-3">
                            <label className="input-title-hb">DNI</label>
                          </div>
                          <div className="col-lg-10 col-md-9">
                            <input
                              readOnly
                              type="text="
                              name="dni"
                              value={values.dni}
                              className="form-control b-radius-20 shadow-blue"
                            />
                            {errors.dni && touched.dni && (
                              <div className="text-danger">{errors?.dni}</div>
                            )}
                          </div>
                        </div>

                        <div className="mb-4 row align-items-center">
                          <div className="col-lg-2 col-md-3">
                            <label className="input-title-hb">Teléfono</label>
                          </div>
                          <div className="col-lg-10 col-md-9">
                            <input
                              type="text"
                              name="celular"
                              value={values.celular}
                              onChange={(e) =>
                                handleChangeCel(e, setFieldValue)
                              }
                              className="form-control b-radius-20 shadow-blue"
                            />
                            {errors.celular && touched.celular && (
                              <div className="text-danger">
                                {errors?.celular}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mb-4 row align-items-center ">
                          <div className="col-lg-2 col-md-3">
                            <label className="input-title-hb">Localidad</label>
                          </div>
                          <div className="col-lg-10 col-md-9">
                            <input
                              type="text="
                              name="localidad"
                              value={values.localidad}
                              onChange={(e) => changeValue(e, setFieldValue)}
                              className="form-control b-radius-20 shadow-blue"
                            />
                            {errors.localidad && touched.localidad && (
                              <div className="text-danger">
                                {errors?.localidad}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mb-4 row align-items-center">
                          <div className="col-lg-12">
                            <label className="input-title-hb mb-2">
                              Seleccione la opción que considere más adecuada a
                              su comentario
                            </label>
                            <select
                              name="opcion"
                              value={values.opcion}
                              onChange={(e) => {
                                handleChange(e);
                                const campoOculto = areas.find(
                                  (item) => item.Area === e.target.value
                                )?.ocultarCampo;
                                if (campoOculto) {
                                  setFieldValue("subOpcion", e.target.value);
                                } else {
                                  setFieldValue("subOpcion", "");
                                }
                              }}
                              className="form-control b-radius-20 shadow-blue select-atencion"
                            >
                              <option value="">Seleccione una opción</option>
                              {/* <option>Agradecimientos</option>
                              <option>Sugerencias</option>
                              <option>Reclamos</option>                             
                              <option>Donaciones</option> */}
                              {areas
                                .map((item) => item.Area)
                                .filter(function (value, index, self) {
                                  return self.indexOf(value) === index;
                                })
                                .sort()
                                .map((item) => (
                                  <option value={item}>{item}</option>
                                ))}
                            </select>
                            {errors.opcion && touched.opcion && (
                              <div className="text-danger">
                                {errors?.opcion}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mb-4 row align-items-center ">
                          {values.opcion &&
                            !areas.find((item) => item.Area === values.opcion)
                              .ocultarCampo && (
                              <div className="col-lg-12">
                                <select
                                  name="subOpcion"
                                  value={values.subOpcion}
                                  onChange={handleChange}
                                  className="form-control b-radius-20 shadow-blue"
                                >
                                  <option value="">
                                    Seleccione una opción
                                  </option>
                                  {areas
                                    ?.filter(
                                      (item) =>
                                        item.Area === values.opcion &&
                                        !item.ocultarCampo
                                    )
                                    ?.sort()
                                    ?.map((item) => (
                                      <option value={item.Campo}>
                                        {item.Campo}
                                      </option>
                                    ))}
                                </select>
                                {errors.subOpcion && touched.subOpcion && (
                                  <div className="text-danger">
                                    {errors?.subOpcion}
                                  </div>
                                )}
                              </div>
                            )}
                        </div>

                        <div className="mb-4 row align-items-center">
                          <div className="col-lg-12">
                            <label className="input-title-hb mb-2">
                              Por favor, escriba su comentario
                            </label>
                            <textarea
                              name="comentario"
                              value={values.comentario}
                              onChange={handleChange}
                              className="form-control b-radius-20 shadow-blue"
                              rows={4}
                            ></textarea>
                            {errors.comentario && touched.comentario && (
                              <div className="text-danger">
                                {errors?.comentario}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mb-4 form-check">
                          <input
                            name="aceptoPrivacidad"
                            ref={aceptoPrivacidadRef}
                            value={values.aceptoPrivacidad}
                            onChange={handleChange}
                            className="form-check-input"
                            type="checkbox"
                            id="defaultCheck1"
                          />
                          <label
                            class="form-check-label"
                            for="defaultCheck1"
                            id="politicas-hb"
                          >
                            Acepto la{" "}
                            <a
                              href="javascript:void(0)"
                              onClick={politicaContactoToggle.onToggle}
                            >
                              {" "}
                              política de privacidad
                            </a>{" "}
                            del Hospital Británico
                          </label>
                          <PoliticaProteccionComponent
                            visible={politicaContactoToggle.toggle}
                            onToggle={politicaContactoToggle.onToggle}
                          />
                          {errors.validateAceptoPrivacidad &&
                            touched.validateAceptoPrivacidad && (
                              <div className="text-danger">
                                {errors?.validateAceptoPrivacidad}
                              </div>
                            )}
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div id="botCaptcha">
                              <ReCAPTCHA
                                ref={captcha}
                                sitekey={process.env.REACT_APP_CAPTCHA}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="mt-4 mb-4">
                              <center>
                                <button
                                  type="button"
                                  onClick={() => handleSubmit()}
                                  className="boton-contacto-hb"
                                  disabled={loading}
                                >
                                  Aceptar
                                </button>
                              </center>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    {/* IMAGEN SOLO EN PC */}
                    <div className="col-lg-6 mb-5 d-md-none d-lg-block columna-imagen">
                      {/* <img
                        src={contacto}
                        className="b-radius-r-20 img-card"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        alt="bg-contacto"
                      /> */}
                      <div
                        className="bg-image2"
                        style={{
                          backgroundImage: `url(${loadedBgImage || ""})`,
                          height: "730px",
                        }}
                      >
                        <div className="bg-image-text2">
                          <div className="bg-image-text2">
                            <div className="transbox2">
                              <h1>Tu opinión nos importa</h1>
                              {/* <h5>
                                Agendá tus turnos, accedé a tus resultados y
                                gestioná tu grupo familiar desde el portal del
                                Hospital Británico
                              </h5> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-0 fila-acepto">
                    <div
                      className="col-12 col-lg-8"
                      style={{ padding: "0px 20px" }}
                    ></div>
                  </div>
                </div>

                {/* MEDIOS DE CONTACTO */}
                <div className="row mt-4">
                  <div className="col-12 col-lg-6">
                    <h2 className="section_contacto_titulo">
                      <b>Oficina Virtual de Atención al Paciente</b>
                    </h2>
                    <p className="subtitulo">
                      Para realizar agradecimientos, reclamos y sugerencias,
                      ponemos a disposición los siguientes canales:
                      <br />
                    </p>
                    <h4 className="subtitulo-footer">
                      <span>
                        Portal WEB:{" "}
                        <a
                          target="_blank"
                          href="https://www.hospitalbritanico.org.ar"
                          className="info-contacto-link"
                        >
                          www.hospitalbritanico.org.ar
                        </a>
                      </span>
                      <br />
                      <span>WhatsApp:</span>{" "}
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://wa.me/541130159749"
                        className="info-contacto-link"
                      >
                        1130159749
                      </a>
                      <br />
                    </h4>
                  </div>
                  {/* BANNER SOLO EN PC */}
                  <div className="col-lg-6" style={{ paddingLeft: 0 }}>
                    <a
                      href="https://plandesalud.hospitalbritanico.org.ar/dejanos-tu-consulta"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={bannerContacto}
                        className="img-card"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                        alt="banner-contacto"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      )}
    </Formik>
  );
};

export default AtencionAlPacienteComponent;
