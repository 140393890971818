// import './dato-contacto.scss'
import { FiEdit2, FiX, FiCheck } from "react-icons/fi";
import React, { useContext } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import { BsTrash } from "react-icons/bs";
import { display } from "@mui/system";

// import { BsFillPencilFill } from "react-icons/bs";
// import { BiPlusMedical } from "react-icons/bi";

import iconEditar from "../../../assets/img/editar.svg";
import iconEliminar from "../../../assets/img/eliminar.svg";

import useIsApple from "../../../hooks/useIsApple";
// import { validateModel } from './validationSchema';
import "./style.scss";

export const IntegranteGrupoComponent = ({
  activo = false,
  editando = false,
  onEditando = () => null,
  confirmar = () => null,
  cancelar = () => null,
  data,
  eliminar,
  editarIntegrante,
  anadir,
}) => {
  const { sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser } =
    useContext(UserGlobalContextMemorySpace);

  const [claseCampo, setClaseCampo] = React.useState("");
  const [temporalUser, setTemporalUser] = React.useState(
    JSON.parse(JSON.stringify(user))
  );
  const { ios } = useIsApple();
  const dniType = { 1: "Cédula", 3: "Libreta", 0: "SI" };

  React.useEffect(() => {
    // console.log(temporalUser.user.data.Customers);
    if (editando) setClaseCampo("campo editandoCampo");
    else setClaseCampo("campo");
  }, [editando]);

  return (
    <div className={activo ? "" : "d-none"}>
      <div className={activo ? "formActivo" : "formInactivo"}>
        {/* CAMPOS DE FORMULARIOS */}
        <form
          className={
            userPSHB ? "formPSHB integrante-pshb" : "formHB integrante-hb"
          }
        >          
          <div className={claseCampo}>
            <span className="perfil-etiqueta">Tipo:</span>
            <span className="perfil-valor">{dniType[data.dniType]}</span>
          </div>
          
          <div className={claseCampo}>
            <span className="perfil-etiqueta">Número:</span>
            <span className="perfil-valor">{data.dni}</span>
          </div>
          
          <div
            className={claseCampo}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span className="perfil-etiqueta">Fecha de nacimiento:</span>
            <span className="perfil-valor">{data.birthday}</span>
          </div>

         {!userPSHB?
         <>          
          <div className={claseCampo}>
            <span className="perfil-etiqueta">Genero:</span>
            <span className="perfil-valor">{data.gender}</span>
          </div>
          
          <div className={claseCampo}>
            <span className="perfil-etiqueta">Estado:</span>
            <span className="perfil-valor">{data.status}</span>
          </div></>:<></>
          }

          <div
            className={claseCampo}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <span className="perfil-etiqueta">Observaciones:</span>
            <span className="perfil-valor">{data.observations}</span>
          </div>

          <div
            className="opciones-card-grupo-familiar"
          >
          
            {data.status!=null?data.statusid == "2" ? (              
                <img src={iconEditar} alt="Icono de editar" onClick={()=>{}} />              
            ) : (              
                <img src={iconEditar} alt="Icono de editar" onClick={editarIntegrante} />              
            ):<></>}



            &nbsp;
            {/* {data.statusid==1 || data.statusid == "2" ? */}          
              <img src={iconEliminar} alt="Icono de editar"   onClick={eliminar}/>            
            {/* } */}

            {data.status==null?
            <button
              className="circle-button"
              onClick={anadir}
              type="button"
            >
              {/* <BiPlusMedical
                size="15px"
                className={`${
                  ios ? "circle-icon-ios" : "circle-icon-all-devices"
                }`}
                style={{
                  color: "white",
                  marginBottom: "2px",
                }}
              /> */}
            </button>:<></>}
          </div>
        </form>
      </div>
    </div>
  );
};
