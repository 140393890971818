// import './dato-contacto.scss'
import { FiEdit2, FiX, FiCheck } from "react-icons/fi";
import React, { useContext } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import { BsTrash } from "react-icons/bs";
import { display } from "@mui/system";
import { BsFillPencilFill } from "react-icons/bs";
import { BiPlusMedical } from "react-icons/bi";
import useIsApple from "../../../hooks/useIsApple";
// import { validateModel } from './validationSchema';
import "./style.scss";

export const HistorialGrupoComponent = ({
  activo = false,
  editando = false,
  onEditando = () => null,
  confirmar = () => null,
  cancelar = () => null,
  data,
  eliminar,
  editarIntegrante,
  anadir,
}) => {
  const { sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser } =
    useContext(UserGlobalContextMemorySpace);

  const [claseCampo, setClaseCampo] = React.useState("");
  const [temporalUser, setTemporalUser] = React.useState(
    JSON.parse(JSON.stringify(user))
  );
  const { ios } = useIsApple();

  const convertirNumero = (numero) => {
    if (numero == null) return "";

    if (!numero.includes("*")) {
      var digitosFinales = numero.toString().slice(-2);
      return `****${digitosFinales}`;
    }

    return numero.toString().slice(-6);
  };

  React.useEffect(() => {
    if (editando) setClaseCampo("campo editandoCampo");
    else setClaseCampo("campo");
  }, [editando]);

  return (
    <div className={activo ? "" : "d-none"}>
      <div className={activo ? "formActivo" : "formInactivo"}>
        {/* CAMPOS DE FORMULARIOS */}
        <form
          className={
            userPSHB ? "formPSHB integrante-pshb" : "formHB integrante-hb"
          }
        >
          <div className={activo ? "lineaActivaPSHB" : "lineaInactivaPSHB"} />
          <div className={claseCampo}>
            <span className="perfil-etiqueta">Débito automático:</span>
            <span
              className="perfil-valor"
              style={{ fontFamily: "Roboto-Regular" }}
            >
              {data.tipoDebito === "cuenta" ? "En cuenta" : "En tarjeta"}
            </span>
          </div>

          {/* <div className={activo ? "lineaActiva" : "lineaInactiva"} /> */}
          <div className={claseCampo}>
            <span className="perfil-etiqueta">Tipo de trámite:</span>
            <span
              className="perfil-valor"
              style={{ fontFamily: "Roboto-Regular" }}
            >
              {data.tipoOperacion}
            </span>
          </div>

          {/* <div className={activo ? "lineaActiva" : "lineaInactiva"} /> */}
          <div
            className={claseCampo}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <span className="perfil-etiqueta">Tarjeta o CBU:</span>
            <span
              className="perfil-valor"
              style={{ fontFamily: "Roboto-Regular" }}
            >
              {convertirNumero(data.cbu || data.numeroTarjeta)}
            </span>
          </div>

          {/* <div className={activo ? "lineaActiva" : "lineaInactiva"} /> */}
          <div
            className={claseCampo}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <span className="perfil-etiqueta">Fecha:</span>
            <span
              className="perfil-valor"
              style={{ fontFamily: "Roboto-Regular" }}
            >
              {data.fecha.split(" ")[0]}
            </span>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          ></div>
        </form>
      </div>
    </div>
  );
};
