import { Col, Row } from "react-bootstrap";
import "./debit-account.scss";
import ResultsComponent from "../../../components/hb/results";
import DebitAccountComponent from "../../../components/pshb/debit-account";
import UserSidebar from "../../../components/common/user-sidebar";
import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

const DebitAccount = (props) => {

  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const location = useLocation();

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <div className="bodypages"></div>
      <DebitAccountComponent
        visible={location.state?.visible ? location.state?.visible : false}
        ref={ref}
      />
    </div>
  );
};

export default DebitAccount;
