import React from "react";
import "./grupo-menu.scss";
import Turnos from "../../../../assets/img/hb/Turnos Home.svg";
import Resultados from "../../../../assets/img/hb/Resultados Home.svg";
import HospitalBritanico from "../../../../assets/img/hb/Hospital Británico Home.svg";
import AtencionPaciente from "../../../../assets/img/hb/Atención al paciente Home.svg";
import { Link } from "react-router-dom";

const grupos = [
  { icono: Turnos, texto: "Turnos" },
  { icono: Resultados, texto: "Resultados" },
  { icono: HospitalBritanico, texto: "Hospital Britanico" },
  { icono: AtencionPaciente, texto: "Atención al paciente" },
];

export const GrupoMenuComponent = () => {
  return (
    <div className="row g-0">
      <div className="col-lg-10 col-xs-12 offset-lg-1 offset-xl-1 ">
        <div className="row g-0" style={{ marginBottom: "-35px" }}>
          {/* <div className="d-none d-sm-block">
            <div className="grandientNavbarHB">
              <div className="grandientNavbar_elements">
                <span className="text-center">
                  <img
                    // src={NumeroAtencion}
                    className="iconosAtencion"
                    alt="..."
                    style={{ fontSize: "25px" }}
                  />{" "}
                  Atención telefónica las 24hs:
                </span>
              </div>
            </div>
          </div> */}
          <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
            <Link to="/turnos">
              <div className="grupoMenuItem">
                <img src={Turnos} className="grupoMenuIcono" alt={"Turnos"} />
                <span className="grupoMenuTexto">Turnos</span>
              </div>
            </Link>
          </div>
          <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
            <Link to="/resultados">
              <div className="grupoMenuItem">
                <img
                  src={Resultados}
                  className="grupoMenuIcono"
                  alt={"Resultados"}
                />
                <span className="grupoMenuTexto">Resultados</span>
              </div>
            </Link>
          </div>

          <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 ">
            <Link to="/atencion-al-paciente" style={{ paddingRight: "7px" }}>
              <div className="grupoMenuItem">
                <img
                  src={AtencionPaciente}
                  className="grupoMenuIcono icono-atencion"
                  alt={"Atención"}
                />
                <span className="grupoMenuTexto">
                  Atención
                  <br />
                  al paciente
                </span>
              </div>
            </Link>
          </div>
          <div className="col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3">
            <a
              href="https://www.hospitalbritanico.org.ar/"
              target="_blank"
              className="grupoMenuItem"
            >
              <img
                src={HospitalBritanico}
                className="grupoMenuIcono"
                alt={"Hospital Británico"}
              />
              <span className="grupoMenuTexto">
                Hospital
                <br />
                Británico
              </span>
            </a>
          </div>
        </div>
      </div>
      {/* <div className="offset-xl-1"></div> */}
    </div>
  );
};
