import React, { useContext } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import classNames from "classnames";

export const TarjetaHistorialComponent = ({
  titulo,
  activo = false,
  puedeMinimizar = false,
  children,
  onVisibleTarjeta,
}) => {
  const { sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser } =
    useContext(UserGlobalContextMemorySpace);

  return (
    <div
      className={classNames("tarjeta", {
        ["tarjeta--activa"]: activo,
        ["tarjeta--pshb"]: userPSHB,
      })}
    >
      <div
        className="d-flex justify-content-start align-items-center"
        style={{ marginLeft: "10px" }}
      >
        <h6 style={{ color: "rgb(3, 50, 97)" }}>N° ID trámite:&nbsp;</h6>
        <h6
          style={{ fontFamily: "Roboto-Regular" }}
          className={activo ? "tituloActivo" : "tituloInactivo"}
        >
          {titulo}
        </h6>
        {/* <IconoCollapse /> */}
      </div>

      {children}
    </div>
  );
};
