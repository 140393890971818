import React, { FC } from "react";
import { Accordion } from "react-bootstrap";
import dataAcordions from "./data.json";
import simboloPregunta from "../../../assets/img/hb/simbolo-pregunta.svg";
import "./ayuda.scss";
import { fetchExternal } from "../../../utils/fetch";
import CommonLoader from "../../common/loader";
import dompurify from "dompurify";

const AyudaComponent = () => {
  const [contenido, setContenido] = React.useState(null);
  const [loader, setLoader] = React.useState(true);
  const sanitizer = dompurify.sanitize;
  const [titulo,setTitulo]=React.useState("")

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoader(true)
    try {
      const { data } = await fetchExternal(
        `${process.env.REACT_APP_STRAPI_QA}/ayudas?idEmpresa=1&codigo=preguntasFrecuentesHB`,
        "GET"
      );
      setContenido(data[0].preguntas);      
      setTitulo(data[0].titulo)
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const RenderAccordion = ({ items }) => {
    return (      
      <Accordion>
        {items &&
          items.map((data, index) => (
            <Accordion.Item
              className="mb-4 element-accordion"
              key={index}
              eventKey={index}
            >
              <Accordion.Header
                className="header-accordion "
                id="caja-ayuda-hb"
              >
                <img
                  style={{ width: "28px", color: "blue" }}
                  className="me-4"
                  src={simboloPregunta}
                />
                {data.descripcionCorta}
              </Accordion.Header>
              <Accordion.Body className="accordion-text-hb">
                <div className="">
                  {data.respuestasenriquecidas.map((respuesta, index) => (
                    <div classaName="container" dangerouslySetInnerHTML={{__html: sanitizer(respuesta?.descripcion)}} />                                                                
                  ))}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
      </Accordion>
    );
  };
  return (
    <section className="" style={{ overflow: "hidden" }}>
      <div
        className="container mt-5 p-4 ayuda-max-width"
        style={{ maxWidth: "100%", paddingRight: "0px" }}
      >
        <div className="row" style={{ paddingRight: "10px" }}>
          <h2 className="titulo-ayuda-hb">
            {/* Información general - Preguntas frecuentes */}
            {titulo}            
          </h2>
        </div>
        <div className="separador-hb"></div>
        <div className="row mt-4">
          <div className="col-lg-12">
            {loader?<CommonLoader />:<RenderAccordion items={contenido} />}
          </div>
        </div>
      </div>
    </section>
  );
};
export default AyudaComponent;
