import AtencionAlPacienteComponent from "../../../components/hb/atencion-al-paciente";
import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import UserSidebar from "../../../components/common/user-sidebar";
import "./index.scss";

const AtencionAlPaciente = () => {
  // const [routes, setRoutes] = useState({
  //   routes: [
  //     { route: "/atencion-al-paciente", name: "Dejanos tu consulta" },
  //     { route: "/certificado-asistencia", name: "Certificados de asistencia" },
  //   ],
  // });
  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <AtencionAlPacienteComponent />
    </div>
  );
};

export default AtencionAlPaciente;
