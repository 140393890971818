import "./dato-personal.scss";
import { FiEdit2, FiX, FiCheck } from "react-icons/fi";
import React, { useContext } from "react";
import { UserGlobalContextMemorySpace } from "../../../../contexts/user/userGlobalContext";

export const DatoPersonalComponent = ({
  activo = false,
  editando = false,
  onEditando = () => null,
}) => {
  const { sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser } =
    useContext(UserGlobalContextMemorySpace);

  const [claseCampo, setClaseCampo] = React.useState("");

  const dni_types = ["DNI", "CI", "PA", "LC"];

  const gender_types = { M: "MUJER", H: "HOMBRE" };

  React.useEffect(() => {
    // console.log(user);
    if (editando) setClaseCampo("campo editandoCampo");
    else setClaseCampo("campo");
  }, [editando]);

  const Desktop = () => {
    return (
      <div className={activo ? "formActivo" : "formInactivo"}>
        {/* LINEA SUPERIOR */}
        <div
          className={
            activo
              ? "lineaCabActiva"
              : userPSHB
              ? "lineaCabInactivaPSHB"
              : "lineaCabInactiva"
          }
        />

        {/* CAMPOS DE FORMULARIOS */}
        <div className={"d-flex flex-column " + claseCampo}>
          <div>
            <span className="perfil-etiqueta">Nombre:</span>
            <span className="perfil-valor">
              {user.user.data.Customers.firstName}
            </span>
          </div>
          <div>
            <span className="perfil-etiqueta">Apellido:</span>
            <span className="perfil-valor">
              {user.user.data.Customers.lastName}
            </span>
          </div>
        </div>

        <div className={activo ? "lineaActiva" : "lineaInactiva"} />
        <div className={"campo-perfil-numero " + claseCampo}>
          <div>
            <span className="perfil-etiqueta">Tipo:</span>
            <span className="perfil-valor">
              {dni_types[user.user.data.Customers.dniType]}
            </span>
          </div>
          <div>
            <span className="perfil-etiqueta">Número:</span>
            <span className="perfil-valor">{user.user.data.Customers.dni}</span>
          </div>
        </div>

        <div className={activo ? "lineaActiva" : "lineaInactiva"} />
        <div className={claseCampo} id="container-date-birth">
          <span className="perfil-etiqueta">Fecha de nacimiento:</span>
          <span className="perfil-valor">
            {user.user.data.Customers.birthday}
          </span>
        </div>
      </div>
    );
  };

  const Mobile = () => {    
    return (<div
      className={activo ? "formActivo" : "formInactivo"}
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: "10px",
        paddingTop: "30px",
      }}
    >
      <div>
        <span className="perfil-etiqueta me-1">Nombre:</span>
        <span className="perfil-valor">
          {user.user.data.Customers.firstName}
        </span>
      </div>
      <div>
        <span className="perfil-etiqueta me-1">Apellido:</span>
        <span className="perfil-valor">
          {user.user.data.Customers.lastName}
        </span>
      </div>

      <div
        className={"campo-perfil-numero"}
        style={{
          display: "grid",
          gridTemplateRows: "1fr",
          gridTemplateColumns: "80px auto",
        }}
      >
        <div>
          <span className="perfil-etiqueta me-1">Tipo:</span>
          <span className="perfil-valor">
            {dni_types[user.user.data.Customers.dniType]}
          </span>
        </div>
        <div>
          <span className="perfil-etiqueta me-1">Número:</span>
          <span className="perfil-valor">{user.user.data.Customers.dni}</span>
        </div>
      </div>

      <div>
        <span className="perfil-etiqueta me-1">Fecha de nacimiento:</span>
        <span className="perfil-valor">
          {user.user.data.Customers.birthday}
        </span>
      </div>
    </div>)
  };

  return (
    <>
      {/* Componente para dispositivos móviles (oculto en pantallas medianas y grandes) */}
      <div class="d-block d-md-none">
        <Mobile />
      </div>

      {/* Componente para pantallas medianas y grandes (oculto en dispositivos móviles) */}
      <div class="d-none d-md-block">
        <Desktop/>
      </div>
    </>
  );
};
