import React, { useContext } from "react";
import Logo1 from "../../../assets/img/logo1.jpg";
import Logo2 from "../../../assets/img/logo2.jpg";
import Logo3 from "../../../assets/img/logo3.png";
import Logo4 from "../../../assets/img/logo4.jpg";
import Logo5 from "../../../assets/img/logo5.jpg";
import Logo6 from "../../../assets/img/logo6.png";
import "./style.scss";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";

const LogosComponent = () => {
  const { userPSHB, footerConfiguration, sitePSHB } = useContext(
    UserGlobalContextMemorySpace
  );
  return (
    <>
      {userPSHB ? (
        <section
          style={{ backgroundColor: "#ededed", height: "3vh" }}
        ></section>
      ) : (
        <></>
      )}
      <section className="section-logos bg-white">
        <div className="container">
          <div
            className="row pb-3 pr-3 d-flex justify-content-center align-items-center"
            style={{ paddingTop: "2.5rem" }}
          >
            <div className="col-4 col-md text-center">
              <a
                href={
                  sitePSHB
                    ? footerConfiguration.data_fiscal
                    : "http://qr.afip.gob.ar/?qr=e7rTZqrC85H6Ftzi3bXXGw,,"
                }
                target="_blank"
              >
                <img src={Logo1} alt className="logosFooter" />
              </a>
            </div>
            <div className="col-4 col-md text-center">
              <a
                href={
                  sitePSHB
                    ? footerConfiguration.registro_nacional
                    : "https://www.argentina.gob.ar/aaip/datospersonales"
                }
                target="_blank"
              >
                <img src={Logo2} alt className="logosFooter" />
              </a>
            </div>
            <div className="col-4 col-md text-center">
              <a
                href={
                  sitePSHB
                    ? footerConfiguration.itaes
                    : "http://www.itaes.org.ar/Inicio.aspx"
                }
                target="_blank"
              >
                <img id="itaes" src={Logo3} alt className="logosFooter" />
              </a>
            </div>
            <div className="col-4 col-md text-center">
              <a
                href={
                  sitePSHB
                    ? footerConfiguration.red_global
                    : "https://hospitalesporlasaludambiental.org"
                }
                target="_blank"
              >
                <img src={Logo4} alt className="logosFooter" />
              </a>
            </div>
            <div className="col-4 col-md text-center">
              <img src={Logo5} alt className="logosFooter" />
            </div>
            <div className="col-4 col-md text-center">
              <img src={Logo6} alt className="logosFooter" id="safe-travel" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default LogosComponent;
