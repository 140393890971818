import React from "react";

import { fetchExternal } from "../../../../utils/fetch";
import { getCookie, saveCookie } from "../../../../utils/cookies";

const useSlide = () => {
  const [slide, setSlide] = React.useState(null);
  React.useEffect(() => {
    (async () => {
      try {
        let imageSlide = getCookie("imageSlide");

        if (imageSlide) {
          setSlide(imageSlide);
        } else {
          const { data } = await fetchExternal(
            `${process.env.REACT_APP_STRAPI_QA}/home-slides?eliminado=false&onOff=true&idEmpresa=1&_sort=orden:ASC`,
            "GET"
          );

          const imageUrl = data[0]?.media?.url;

          if (imageUrl) {
            saveCookie("imageSlide", imageUrl, 1);
          }

          setSlide(imageUrl);
        }
      } catch (error) {
        console.log(error.message);
      }
    })();
  }, []);

  return {
    slide,
  };
};

export default useSlide;
