import React, { useState, useContext, useEffect } from "react";
import { InformacionComponent as InformacionComponentPSHB } from "../../components/pshb/family-group/informacion";
import { InformacionComponent as InformacionComponentHB } from "../../components/hb/family-group/informacion";
import "./family-group.scss";
import { UserGlobalContextMemorySpace } from "../../contexts/user/userGlobalContext";
import { MdError } from "react-icons/md";
import { BsTrash } from "react-icons/bs";
import { BiPlusMedical } from "react-icons/bi";
import { SwalMessage } from "../../utils/sweet-alert/sweet-alert";
import { myAxios } from "../../utils/fetch";
import { IntegranteGrupoComponent } from "../../components/common/familiar-group/Integrante-grupo-component";
import { TarjetaGrupoFamiliarComponent } from "../../components/common/familiar-group/tarjeta-grupo-familiar";
import InformationIcon from "../../assets/img/information.svg";
import ReactGA from "react-ga4";
import { Modal } from "react-bootstrap";
import { familyGroupFilterPSHB } from "../../hooks/familyGroupFilter";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { useToggle } from "../../hooks/useToggle";
import { useSearchParams } from "react-router-dom";

import useFG from "./hooks/useFG";

const FamilyGroupPSHB = ({ isPSHB }) => {
  const { userPSHB, user, familiarGroup, setFamiliarGroup } = useContext(
    UserGlobalContextMemorySpace
  );

  const [loader, setLoader] = useState(true);
  const [familiarGroupError, setFamiliarGroupError] = useState(false);
  const [integranteVisible, setIntegranteVisible] = useState("");
  const [visible, setVisible] = React.useState(false);
  const [data, setData] = React.useState({
    data: "",
    index: "",
  });
  const infoToggle = useToggle();

  const [searchParams, setSearchParams] = useSearchParams({});
  const openDialogParams = searchParams.get("open");
  const { setOnlyGF } = useFG();

  const getFamiliarGroup = async () => {
    let res = await myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        process.env.REACT_APP_GATEWAY_ENDPOINT_GETFAMILIARGROUPBYID,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      data: {
        idCustomer: user.user.data.Customers.idCustomer,
      },
    })
      .then((response) => {
        if (
          response.data.data.MergedFamilyGroup &&
          response.data.data.PrestaciFamilyGroup
        ) {
          let filterFamilyGroup = familyGroupFilterPSHB(
            response.data.data.MergedFamilyGroup,
            response.data.data.PrestaciFamilyGroup
          );
          return filterFamilyGroup;
        }
      })
      .catch((error) => {
        setFamiliarGroupError(true);
        setLoader(false);
      });

    if (Array.isArray(res)) {
      setOnlyGF(res);
      setLoader(false);
    } else {
      setFamiliarGroupError(true);
      setLoader(false);
    }
  };

  const deleteFamilyGroupMember = async (member, index) => {
    member = data.data;

    setVisible(false);
    setLoader(true);

    let res = await myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        process.env.REACT_APP_GATEWAY_ENDPOINT_UNLINKFAMILYMEMBER,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      data: {
        idCustomer: user.user.data.Customers.idCustomer,
        familyMember: member,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        setLoader(false);
        SwalMessage(
          "info",
          '<h5 style="color: #224d7c"></h5>',
          "No pudimos realizar la accion indicada. Por favor intenta nuevamente en unos minutos",
          "Continuar"
        );
      });

    if (res.data.responseCode == 200) {
      getFamiliarGroup();
    } else {
      SwalMessage(
        "info",
        '<h5 style="color: #224d7c"></h5>',
        "No pudimos realizar la accion indicada. Por favor intenta nuevamente en unos minutos",
        "Continuar"
      );
    }
  };

  const addFamilyGroupMember = async (member, index) => {
    setLoader(true);

    member.statusCode = "2";

    let res = await myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        process.env.REACT_APP_GATEWAY_ENDPOINT_ACCEPTFAMILYMEMBERFROMPRESTACI,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      data: {
        idCustomer: user.user.data.Customers.idCustomer,
        familyMember: member,
      },
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        setLoader(false);
        SwalMessage(
          "info",
          '<h5 style="color: #224d7c"></h5>',
          "No pudimos realizar la accion indicada. Por favor intenta nuevamente en unos minutos",
          "Continuar"
        );
      });

    if (res.data.responseCode == 200) {
      getFamiliarGroup();
    } else {
      SwalMessage(
        "info",
        '<h5 style="color: #224d7c"></h5>',
        "No pudimos realizar la accion indicada. Por favor intenta nuevamente en unos minutos",
        "Continuar"
      );
    }
  };

  useEffect(() => {

    if (isPSHB) {
      if (!familiarGroup) {
        getFamiliarGroup();
      } else {
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  }, []);

  const llamar = (index) => {
    setIntegranteVisible((previus) => (previus === index ? "" : index));
  };

  const informacion = () => {
    infoToggle.onToggle();
    ReactGA.event({
      category: `Acciones-GrupoFamiliar-${userPSHB ? "PSHB" : "HB"}`,
      action: "Click",
      label: "information",
    });
  };

  useEffect(() => {
    if (openDialogParams == "1") {
      infoToggle.onToggle();
    }
  }, []);

  const eliminar = (data, index) => {
    setData({ data: data, index: index });
    setVisible(true);
  };

  return (
    <div
      className="container"
      style={{ maxWidth: "100%", paddingRight: "0", scrollPaddingLeft: "0" }}
    >
      <div className="p-4 p4-fix" style={{ minHeight: "70vh" }}>
        {/* TITULO */}
        <div className="row"></div>
        <div className="row" style={{ marginTop: "3rem" }}>
          <div className="col-lg-4">
            <h2 className="section_hb_title title_mobile">
              <b className="section_hb_title">Mi grupo familiar</b>
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12" id="header-invoice">
            <span className="section_hb_subtitle title_mobile">
              Desde esta opción puede observar a todo su grupo familiar activo y
              el estado en que se encuentra cada uno
            </span>
            <a data-tooltip-id="my-tooltip" data-tooltip-content="Ayuda">
              <ReactTooltip id="my-tooltip" />{" "}
              <img
                id="icon-information"
                className="information_mobile"
                onClick={() => informacion()}
                src={InformationIcon}
                alt="..."
                style={{ height: "28px" }}
              ></img>
            </a>
            {userPSHB ? (
              <InformacionComponentPSHB
                visible={infoToggle.toggle}
                onToggle={infoToggle.onToggle}
              />
            ) : (
              <InformacionComponentHB
                visible={infoToggle.toggle}
                onToggle={infoToggle.onToggle}
              />
            )}
          </div>
        </div>

        {/* SEPARADOR */}
        <div
          className={
            userPSHB
              ? "separador-table-green-result"
              : "separador-table-red-result"
          }
        ></div>
        <div id="contenedor-grupo-familiar-desktop">
          {/* TABLA */}
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="table-responsive">
                <table
                  className="table table-hb table-pshb-result"
                  id="tableResults"
                >
                  {loader ? (
                    <>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "295px" }}
                      >
                        <div
                          style={{
                            width: "1rem",
                            height: "1rem",
                            color: "#5d84aa",
                          }}
                          className="spinner-grow text-muted"
                        ></div>
                        <div
                          style={{
                            marginLeft: "5px",
                            width: "1rem",
                            height: "1rem",
                            color: "#5d84aa",
                          }}
                          className="spinner-grow text-muted"
                        ></div>
                        <div
                          style={{
                            marginLeft: "5px",
                            width: "1rem",
                            height: "1rem",
                            color: "#5d84aa",
                          }}
                          className="spinner-grow text-muted"
                        ></div>
                      </div>
                    </>
                  ) : (
                    <>
                      {familiarGroup && familiarGroup.length > 0 ? (
                        <>
                          <thead>
                            <tr>
                              <th>Nombre y apellido</th>
                              <th>Tipo</th>
                              <th>N°</th>
                              <th>Fecha de nacimiento</th>
                              <th>Estado</th>
                              <th>Observaciones</th>
                              <th>Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {familiarGroup.map((data, index) => {
                              return (
                                <tr key={index}>
                                  <td>{data.fullName}</td>
                                  <td>
                                    {data.dniType == 0
                                      ? "DNI"
                                      : data.dniType == 2
                                      ? "Pasaporte"
                                      : data.dniType}
                                  </td>
                                  <td>{data.dni}</td>
                                  <td>{data.birthday}</td>
                                  <td>
                                    {data.status == null
                                      ? "Pendiente de agregar al grupo familiar"
                                      : "Aceptado"}
                                  </td>
                                  <td>{data.observations}</td>
                                  <td>
                                    {data.status == null ? (
                                      <button
                                        className="circle-button circle-button-blue"
                                        onClick={() =>
                                          addFamilyGroupMember(data, index)
                                        }
                                      >
                                        <BiPlusMedical
                                          size="15px"
                                          style={{
                                            color: "white",
                                            marginBottom: "2px",
                                          }}
                                        />
                                      </button>
                                    ) : (
                                      <button
                                        className="circle-button circle-button-red"
                                        onClick={() => eliminar(data, index)}
                                      >
                                        <BsTrash
                                          size="15px"
                                          style={{
                                            color: "white",
                                            marginBottom: "2px",
                                          }}
                                        />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </>
                      ) : (
                        <>
                          <thead>
                            <tr>
                              <th>
                                <p
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "normal",
                                    fontFamily: "Roboto-Medium",
                                  }}
                                >
                                  No hay miembros en tu plan. Comunícate con
                                  nuestro equipo de ventas para agregar
                                  integrantes a tu grupo familiar
                                </p>
                              </th>
                            </tr>
                          </thead>
                        </>
                      )}
                    </>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
        <div id="contenedor-grupo-familiar-mobile">
          {familiarGroup && familiarGroup.length > 0 ? (
            <>
              {familiarGroup?.map((integrante, index) => {
                return (
                  <TarjetaGrupoFamiliarComponent
                    key={index}
                    titulo={integrante.fullName}
                    activo={false}
                    abierto={integranteVisible === index}
                    puedeMinimizar={true}
                    onVisibleTarjeta={(_) => llamar(index)}
                  >
                    <IntegranteGrupoComponent
                      data={integrante}
                      activo={integranteVisible === index}
                      editando={false}
                      eliminar={() =>
                        deleteFamilyGroupMember(integrante, index)
                      }
                      anadir={() => addFamilyGroupMember(integrante, index)}
                    />
                  </TarjetaGrupoFamiliarComponent>
                );
              })}
            </>
          ) : (
            <>
              <thead>
                <tr>
                  <th>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "normal",
                        fontFamily: "Roboto-Medium",
                      }}
                    >
                      No hay miembros en tu plan. Comunicate con nuestro equipo
                      de ventas para agregar integrantes a tu grupo familiar.{" "}
                      {familiarGroupError ? (
                        <MdError size="30px" style={{ color: "#033261" }} />
                      ) : null}
                    </p>
                  </th>
                </tr>
              </thead>
            </>
          )}
        </div>
        {/* SEPARADOR */}
        <div
          className={
            userPSHB
              ? "separador-table-green-result"
              : "separador-table-red-result separador-table-red-result2"
          }
        ></div>
      </div>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={visible}
        centered
      >
        <Modal.Body>
          <div className="modalBody">
            <div className="row mt-2 mx-auto">
              <div className="col-lg-12 ">
                <h2 className="section_pshb_title">
                  <center>
                    <b>
                      ¿Estás seguro que querés eliminar a esta persona de tu
                      Grupo Familiar?
                    </b>
                  </center>
                </h2>
              </div>
            </div>
            <div className="separador-table-green-result mb-4"></div>

            <div className="tutorial-credencial">
              <div className="section_pshb_subtitle_2 checkbox-container">
                <label>
                  <center>
                    <strong>
                      Tener en cuenta que eliminar el integrante no implica baja
                      de cobertura médica.
                    </strong>
                  </center>
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <div className="mx-auto">
            <button
              className="boton-volver"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancelar
            </button>
            <button
              className="boton-confirmar"
              onClick={async () => await deleteFamilyGroupMember()}
            >
              Aceptar
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FamilyGroupPSHB;
