import "./index.scss";
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React  from "react";

const FileInput=({onChange,error=false,size=5048576})=>{

    const fileTypes=["application/pdf","image/png","image/jpg","image/jpeg"];
    const [fileSelected,setFile]=React.useState({label:"",error:false});

    const seleccionarArchivo=()=>{
        var input = document.createElement('input');
        input.type = 'file';
        input.accept=".jpg,.jpeg,.png,.pdf"        
        input.onchange = e => { 
            var file = e.target.files[0]; 
                        
            if(fileTypes.indexOf(file.type)===-1)
            {
                setFile({label:"Archivo invalido",error:true,errorMessage:"Solo se permiten imagenes JPG,JPEG,PNG y PDF"});
                onChange(null)
                return;
            }

            if(file.size>5048576)
            {
                setFile({label:"Archivo pesado",error:true,errorMessage:"Por favor verifica que tu archivo pese menos de 5 MB"});
                onChange(null)
                return;
            }

            // console.log("FILE",file);

            setFile({label:file.name,error:false,file:file})
            onChange(file);
        }
         
        input.click();
    }

    return(        
        // <div className="input-icons me-3">
        <div>
            <div className="custom-load-icon" style={{height:"30px",minHeight:"30px"}} onClick={seleccionarArchivo}>
                <FontAwesomeIcon icon={faPaperclip}/>
                <span>Adjuntar</span>
            </div>
            <div style={{display:"flex",flexDirection:"column",marginLeft: "100px"}}>
                
                <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                    <span className={(fileSelected.error?"error-file ":"")+"round-label w-100 shadow-blue"} style={{maxWidth: "300px",display:"flex",alignItems:"center",minHeight:"30px",height:"30px"}}>{fileSelected.label}{error?"Archivo requerido":""}</span>
                    {
                        fileSelected.label
                        ?
                        fileSelected.error
                            ?
                            <FontAwesomeIcon style={{marginLeft:"3px",height:"30px",color:"red"}} icon={faCircleXmark}/>
                            :
                            <FontAwesomeIcon style={{marginLeft:"3px",height:"30px",color:"green"}} icon={faCheckCircle}/>
                        :
                        <></>
                    }
                </div>
                
                {fileSelected.error
                  ?
                    <span className={fileSelected.error?"error-file":""} style={{marginLeft:"12px",fontSize:"10px"}}>{fileSelected.errorMessage}</span>
                  :
                    <></>

                }
            </div>
        </div>
    );
}

export default FileInput;