import { Col, Row } from "react-bootstrap";
import "./invoices.scss";
import ResultsComponent from "../../../components/hb/results";
import InvoicesComponent from "../../../components/pshb/invoices";
import UserSidebar from "../../../components/common/user-sidebar";
import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { fetchLogs } from "../../../utils/fetch";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";

const Invoices = (props) => {

  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const location = useLocation();
  const {    
    user,    
  } = useContext(UserGlobalContextMemorySpace);

  useLayoutEffect(() => {
    // fetchLogs({
    //   idLogType: 1,
    //   idType: 17,                  
    //   description:"El usuario " + user.user.data.Customers.lastName + " " + user.user.data.Customers.firstName + " accedió al área de facturación",                  
    // });
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <div className="bodypages"></div>
      <InvoicesComponent
        visible={location.state?.visible ? location.state?.visible : false}
        ref={ref}
      />
    </div>
  );
};

export default Invoices;
