import React, { useContext } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import "./index.scss";
import contacto from "../../../assets/img/pshb/bg-dejanos-tu-consulta.jpg";
import bannerContacto from "../../../assets/img/hb/bannerContactoPSHB.png";
import { fetchExternal } from "../../../utils/fetch";
import { useToggle } from "../../../hooks/useToggle";
import { validateModelFacturacion } from "../atencion-al-paciente/validationSchema";

import { Formik } from "formik";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import { PoliticaProteccionComponent } from "../../../components/common/footer/politica-proteccion";
import { BASE_URL_STRAPPI } from "../../../utils/constants";
import useProgressiveImage from "./../../../hooks/useProgressiveImage";
import areas from "../../../assets/data/areas.json";
import { useEffect } from "react";

const SugerenciasHB = () => {
  const loadedBgImage = useProgressiveImage(contacto);
  const captcha = React.useRef(null);
  const libroSugerenciasRef = React.useState(null);
  const politicaContactoToggle = useToggle();
  const { userPSHB, user } = useContext(UserGlobalContextMemorySpace);
  const [temporalUser, setTemporalUser] = React.useState(
    JSON.parse(JSON.stringify(user))
  );

  const initialValues = {
    nombre: user.user.data.Customers.firstName,
    apellido: user.user.data.Customers.lastName,
    email: user.user.data.Customers.email,
    dni: user.user.data.Customers.dni,
    celular: temporalUser.user.data.Customers.mobile,
    localidad: "",
    telefono: "",
    opcion: "",
    subOpcion: "",
    comentario: "",
    aceptoPrivacidad: false,
    validateAceptoPrivacidad: "",
  };

  const onSubmit = async (values, resetForm) => {
    if (captcha.current?.getValue()) {
      let area = areas.find((area) => area.Campo === values.subOpcion);

      const correo = {
        to:
          process.env.REACT_APP_ENVIROMENT_DEBUG === "true"
            ? process.env.REACT_APP_MAIL_DEBUG
            : area.Mail,
        subject:
          (process.env.REACT_APP_ENVIROMENT_DEBUG === "true" ? "QA-" : "") +
          "HB Online - " +
          values.opcion +
          " - " +
          values.subOpcion,
        text: `<b>Nombre:</b> ${values.nombre} <br/>
        <b>Apellido:</b> ${values.apellido} <br/>
        <b>Email:</b> ${values.email} <br/>
        <b>DNI:</b> ${values.dni} <br/>
        <b>Celular:</b> ${values.celular} <br/>        
        <b>Localidad:</b> ${values.localidad} <br/>        
        <b>Motivo:</b> ${values.opcion} <br/>        
        <b>Mensaje:</b> ${values.comentario} <br/>        
        `,
      };

      const { data } = await fetchExternal(
        BASE_URL_STRAPPI + "/email",
        "post",
        correo
      );
      resetForm();
      libroSugerenciasRef.current.checked = false;
      captcha.current?.reset();
      Swal.fire("", "Solicitud de contacto enviada con éxito", "success");
    } else {
      Swal.fire("", "Debe indicar que no es un robot", "error");
    }
  };

  const handleChangeCel = (e, setFieldValue) => {
    const { value } = e.target;
    const onlyNumbers = /^[0-9]+$/; // Expresión regular para validar números
    if (value === "" || (onlyNumbers.test(value) && value.length <= 12)) {
      setFieldValue(e.target.name, value);
    }
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // },[]);

  return (
    <section
      className="section_contacto"
      style={{ minHeight: "calc(100vh - 25px - 100px - 200px)" }}
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 p-5 columna-contenido-sugerencias">
            <Formik
              initialValues={initialValues}
              // enableReinitialize
              validationSchema={validateModelFacturacion}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                onSubmit(values, resetForm);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form className="section_contacto_form" onSubmit={handleSubmit}>
                  {/* BANNER SOLO EN MOBILE */}
                  <div className="row col-12" style={{ paddingLeft: 0 }}>
                    <a
                      href="https://plandesalud.hospitalbritanico.org.ar/dejanos-tu-consulta"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={bannerContacto}
                        className="img-card-mobile pb-3"
                        style={{
                          height: "100%",
                          width: "104%",
                          objectFit: "cover",
                        }}
                        alt="banner-contacto"
                      />
                    </a>
                  </div>
                  <h2 className={userPSHB ? "title-pshb" : "title-hb"}>
                    <b className="title-hb">Libro de sugerencias</b>
                    <div
                      className={
                        userPSHB
                          ? "separador-table-green-result"
                          : "separador-table-red-result"
                      }
                    />
                  </h2>
                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-2">
                      <label
                        className={
                          userPSHB ? "input-title-pshb" : "input-title-hb"
                        }
                      >
                        Nombre
                      </label>
                    </div>
                    <div className="col-lg-10">
                      <input
                        type="text"
                        name="nombre"
                        value={values.nombre}
                        className="form-control b-radius-20 shadow-blue "
                        disabled
                      />
                      {errors.nombre && touched.nombre && (
                        <div className="text-danger">{errors?.nombre}</div>
                      )}
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-2">
                      <label
                        className={
                          userPSHB ? "input-title-pshb" : "input-title-hb"
                        }
                      >
                        Apellido
                      </label>
                    </div>
                    <div className="col-lg-10">
                      <input
                        type="text"
                        name="apellido"
                        value={values.apellido}
                        className="form-control b-radius-20 shadow-blue"
                        disabled
                      />
                      {errors.apellido && touched.apellido && (
                        <div className="text-danger">{errors?.apellido}</div>
                      )}
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-2">
                      <label
                        className={
                          userPSHB ? "input-title-pshb" : "input-title-hb"
                        }
                      >
                        Email
                      </label>
                    </div>
                    <div className="col-lg-10">
                      <input
                        type="text"
                        name="email"
                        value={values.email}
                        className="form-control b-radius-20 shadow-blue"
                        disabled
                      />
                      {errors.email && touched.email && (
                        <div className="text-danger">{errors?.email}</div>
                      )}
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-2">
                      <label className="input-title-hb">DNI</label>
                    </div>
                    <div className="col-lg-10">
                      <input
                        readOnly
                        type="text="
                        name="dni"
                        value={values.dni}
                        className="form-control b-radius-20 shadow-blue"
                      />
                      {errors.dni && touched.dni && (
                        <div className="text-danger">{errors?.dni}</div>
                      )}
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-2">
                      <label className="input-title-hb">Teléfono</label>
                    </div>
                    <div className="col-lg-10">
                      <input
                        type="text"
                        name="celular"
                        value={values.celular}
                        onChange={(e) => handleChangeCel(e, setFieldValue)}
                        className="form-control b-radius-20 shadow-blue"
                      />
                      {errors.celular && touched.celular && (
                        <div className="text-danger">{errors?.celular}</div>
                      )}
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center ">
                    <div className="col-lg-2">
                      <label className="input-title-hb">Localidad</label>
                    </div>
                    <div className="col-lg-10">
                      <input
                        type="text="
                        name="localidad"
                        value={values.localidad}
                        onChange={handleChange}
                        className="form-control b-radius-20 shadow-blue"
                      />
                      {errors.localidad && touched.localidad && (
                        <div className="text-danger">{errors?.localidad}</div>
                      )}
                    </div>
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-12">
                      <label className="input-title-hb mb-2">
                        Seleccione la opción que considere más adecuada a su
                        comentario
                      </label>
                      <select
                        name="opcion"
                        value={values.opcion}
                        onChange={(e) => {
                          handleChange(e);
                          const campoOculto = areas.find(
                            (item) => item.Area === e.target.value
                          )?.ocultarCampo;
                          if (campoOculto) {
                            setFieldValue("subOpcion", e.target.value);
                          } else {
                            setFieldValue("subOpcion", "");
                          }
                        }}
                        className="form-control b-radius-20 shadow-blue"
                      >
                        <option value="">Seleccione una opción</option>
                        {areas
                          .map((item) => item.Area)
                          .filter(function (value, index, self) {
                            return self.indexOf(value) === index;
                          })
                          .sort()
                          .map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                      </select>
                      {errors.opcion && touched.opcion && (
                        <div className="text-danger">{errors?.opcion}</div>
                      )}
                    </div>

                    {values.opcion &&
                      !areas.find((item) => item.Area === values.opcion)
                        .ocultarCampo && (
                        <div className="col-lg-12 mt-3">
                          <select
                            name="subOpcion"
                            value={values.subOpcion}
                            onChange={handleChange}
                            className="form-control b-radius-20 shadow-blue"
                          >
                            <option value="">Seleccione una opción</option>
                            {areas
                              ?.filter(
                                (item) =>
                                  item.Area === values.opcion &&
                                  !item.ocultarCampo
                              )
                              ?.sort()
                              ?.map((item) => (
                                <option value={item.Campo}>{item.Campo}</option>
                              ))}
                          </select>
                          {errors.subOpcion && touched.subOpcion && (
                            <div className="text-danger">
                              {errors?.subOpcion}
                            </div>
                          )}
                        </div>
                      )}
                  </div>

                  <div className="mb-4 row align-items-center">
                    <div className="col-lg-12">
                      <label
                        className={
                          userPSHB
                            ? "input-title-pshb red-asterisk"
                            : "input-title-hb"
                        }
                      >
                        Mensaje <span>*</span>
                      </label>
                      <textarea
                        name="comentario"
                        value={values.comentario}
                        onChange={handleChange}
                        className="form-control b-radius-20 shadow-blue"
                        rows="4"
                      ></textarea>
                      {errors.comentario && touched.comentario && (
                        <div className="text-danger">{errors?.comentario}</div>
                      )}
                    </div>
                  </div>

                  <div className="mb-4 form-check">
                    <input
                      name="aceptoPrivacidad"
                      ref={libroSugerenciasRef}
                      value={values.aceptoPrivacidad}
                      onChange={handleChange}
                      className="form-check-input"
                      type="checkbox"
                      id="defaultCheck1"
                    />
                    <label
                      class="form-check-label red-asterisk"
                      for="defaultCheck1"
                      id={userPSHB ? "politicas-pshb" : "politicas-hb"}
                    >
                      Acepto la{" "}
                      <a
                        href="javascript:void(0)"
                        onClick={politicaContactoToggle.onToggle}
                      >
                        {" "}
                        política de privacidad
                      </a>{" "}
                      del Hospital Británico <span>*</span>
                    </label>
                    <PoliticaProteccionComponent
                      visible={politicaContactoToggle.toggle}
                      onToggle={politicaContactoToggle.onToggle}
                    />
                    {errors.validateAceptoPrivacidad &&
                      touched.validateAceptoPrivacidad && (
                        <div className="text-danger">
                          {errors?.validateAceptoPrivacidad}
                        </div>
                      )}
                  </div>
                  <div className="mb-4 captcha">
                    <div className="row">
                      <div className="col">
                        <div id="botCaptcha">
                          <ReCAPTCHA
                            ref={captcha}
                            sitekey={process.env.REACT_APP_CAPTCHA}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <center>
                      <button
                        type="submit"
                        className={
                          userPSHB ? "boton-contacto" : "boton-contacto-hb"
                        }
                      >
                        Aceptar
                      </button>
                    </center>
                  </div>
                </form>
              )}
            </Formik>
          </div>
          <div className="col-lg-6 d-md-none d-lg-block columna-imagen-sugerencias">
            <div
              className="img-contacto img-contacto-mobile"
              style={{ backgroundImage: `url(${loadedBgImage || ""})` }}
            ></div>
            {/* BANNER SOLO EN PC */}
            <div className="columna-imagen-sugerencias pt-3">
              <a
                href="https://plandesalud.hospitalbritanico.org.ar/dejanos-tu-consulta"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={bannerContacto}
                  className="img-card"
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                  }}
                  alt="banner-contacto"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SugerenciasHB;
