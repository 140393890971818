import RegisterHB from '../../../pages/hb/register';

const CommonRegister = (props) => {

    return (
        <>
            <RegisterHB></RegisterHB>
        </>
    );
}

export default CommonRegister;
