import "./perfil.scss";
import UserSidebar from "../../../components/common/user-sidebar";
import { DatosCoberturaComponent } from "../../../components/common/perfil/dato-cobertura";
import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import FooterHB from "../../../assets/img/hb/Backgrounds/FooterRojoLarge.png";
import FooterPSHB from "../../../assets/img/pshb/Backgrounds/FooterVerde.png";

const Perfil = () => {

  const ref = useRef(null);
  const [height, setHeight] = useState(0);
  const { userPSHB } = useContext(UserGlobalContextMemorySpace);

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <div className="d-flex flex-column w-100 justify-content-between">
        <div
          style={{ width: "100%", paddingLeft: "6.31%", paddingRight: "6.31%" }}
        >
          <DatosCoberturaComponent> </DatosCoberturaComponent>
        </div>
      </div>
    </div>
  );
};

export default Perfil;
