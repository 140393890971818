import { Col, Row } from "react-bootstrap";
import "./family-group.scss";
import FamilyGroupComponent from "../../../components/hb/family-group";
import UserSidebar from "../../../components/common/user-sidebar";
import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import classNames from "classnames";

const FamilyGroup = ({}) => {

  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <div className="d-flex flex-column" style={{ width: "100%", position: "relative" }}>
        <div className="family-group-pshb">
          <FamilyGroupComponent />
        </div>
      </div>
    </div>
  );
};

export default FamilyGroup;
