import React from 'react';
import LogoutComponentHB from '../../../components/hb/logout';
import "./logout.scss";

const LogoutHB = (props) => {

   
  return (
    <div className='logout' style={{display:"grid",gridTemplateRows:"1fr auto"}}>
      <LogoutComponentHB />
      {/* <div className='footer-hb-logout'></div> */}
    </div>
  );
}

export default LogoutHB;
