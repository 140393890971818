import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import "./invoices.scss";
import RefundComponent from "../../../components/pshb/refund";
import UserSidebar from "../../../components/common/user-sidebar";

const ReintegrosEstado = () => {

  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <RefundComponent> </RefundComponent>
    </div>
  );
};

export default ReintegrosEstado;
