import "../home/style.scss";

import React, { useContext } from "react";

import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import NumeroAtencionMobile from "../../../assets/img/hb/numero-atencion-mobile.svg";
import { Information } from "../../../components/common/information/information";
import IcoWPMobile from "../../../assets/img/hb/IconWhatsappMobile.svg";
import WhatsappComponent from "../../../components/common/whatsapp";
import { useToggle } from "../../../utils/hooks/useToggle";
import { GrupoMenuComponent } from "../home/grupo-menu";
import { fetchExternal } from "../../../utils/fetch";
import useSlide from "./hooks/useSlide";

const HomeHB = () => {
  const { user } = useContext(UserGlobalContextMemorySpace);

  const [novedad, setNovedad] = React.useState(false);
  const [loader, setLoader] = React.useState(true);

  const [info, setInfo] = React.useState(null);
  const informationToggle = useToggle();

  const { slide } = useSlide();

  React.useEffect(() => {
    const dniType = user.user.data.Customers.dniType;
    if (dniType === "1" || dniType === "3") {
      informationToggle.onToggle();
    }
    getInfo();
  }, []);

  const getInfo = async () => {
    try {
      const { data } = await fetchExternal(
        `${process.env.REACT_APP_STRAPI_QA}/pagina-principals?idEmpresa=1`,
        "GET"
      );
      setInfo(data[0]);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <>
      <Information
        visible={informationToggle.toggle}
        tipo={user.user.data.Customers.dniType}
        onToggle={informationToggle.onToggle}
        sitio="HB"
      />
      <WhatsappComponent />

      <div className="contenedorBanner">
        <img
          src={slide}
          className="imagenFondo"
          onMouseEnter={() => setNovedad(false)}
          alt="Banner Principal"
        ></img>

        <div className="d-block">
          <div className="planSalud" style={{ minHeight: "40px" }}>
            <span onClick={() => window.open(info?.enlaceNovedad, "_blank")}>
              {info?.novedad && (
                <>
                  {info.novedad} <span className="spanRed">VER MÁS</span>
                </>
              )}
            </span>
          </div>
        </div>

        {/* Bienvenida */}
        <div className="bienvenidaHB-movil bienvenidaHB-pc fs-1">
          <span>
            {" "}
            Te damos la bienvenida <br />a tu HB Online
          </span>
        </div>

        {/* Atenciòn al público */}
        <div className="atencion">
          <span className="text-center-mobile">
            <img
              src={NumeroAtencionMobile}
              className="iconosAtencion"
              alt="..."
              style={{ fontSize: "25px" }}
            />
            Atención telefónica las 24hs: {info?.atencionTelefonica} / Central
            de turnos {info?.centralTurno} /{" "}
            <img
              src={IcoWPMobile}
              className="iconosAtencion"
              style={{ paddingBottom: "4px" }}
            />{" "}
            {info?.whatsapp}
          </span>
        </div>

        {/* Menú grupo */}
        <div className="contenedorGrupoMenu-movil contenedorGrupoMenu-lg contenedorGrupoMenu-xl contenedorGrupoMenu-xxl">
          <GrupoMenuComponent />
        </div>
      </div>
    </>
  );
};

export default HomeHB;
