import React, { useContext, useState } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import style from "./style.scss";
import bgImageLogin from "./../../../assets/img/hb/image-login.jpg";
import bgImageLoginPSHB from "./../../../assets/img/hb/test2.jpg";
//import logoHB from '../../../assets/img/hb/logo.png'
import logoHB from "../../../assets/img/hb/Isologotipo.png";
// import logoPSHB from "../../../assets/img/pshb/logo.jpg";
import logoPSHB from "../../../assets/img/logos/logo-plan.png";

import { Link, useSearchParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { emailValidator } from "../../../utils/hooks/regex";
import { fetchLogs } from "../../../utils/fetch";
import Overlay from "../overlay";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useProgressiveImage from "../../../hooks/useProgressiveImage";

const CommonPasswordRecovery = (props) => {
  const [loader, setLoader] = useState(false);
  const [newPinButton, setNewPinButton] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [checkMail, setCheckMail] = useState("");
  const [hideButton, setHideButton] = useState(false);
  const [inputMail, setInputMail] = useState("");

  const loadedBgImage = useProgressiveImage(bgImageLogin);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams({});
  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const navigate = useNavigate();

  const SwalMessage = (icon, title, text, confirmButtonText) => {
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
      color: "#224d7c",
      confirmButtonColor: "#224d7c",
      confirmButtonText: confirmButtonText,
    });
  };

  const handlerRecuperarContraseña = (event) => {
    event.preventDefault();
    setErrorMsg("");

    let body = {
      email: email,
      token: token,
      password: password,
      pshb: false,
      typeEmail: "EMAIL_BODY_RECOVER",
    };

    let specialCharacterValidation = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let upperCaseValidation = /[A-Z]/;
    if (!email) {
      if (inputMail == "") {
        setErrorMsg("* Por favor ingresa un mail para avanzar.");
      } else if (emailValidator(inputMail)) {
        setErrorMsg("* Por favor ingresa un formato de email valido");
      } else {
        setLoader(true);
        axios({
          url:
            process.env.REACT_APP_STRAPI_QA +
            process.env.REACT_APP_STRAPI_QA_ENDPOINT_PASSRECOVERY,
          method: "POST",
          data: {
            email: inputMail,
            pshb: false,
            typeEmail: "EMAIL_BODY_CHANGEPASSWORD",
          },
        })
          .then((res) => {
            if (res.data.responseCode && res.data.responseCode == 200) {
              setDisabledButton(true);
              setLoader(false);
              setCheckMail("Revisa tu casilla de mail.");
              setHideButton(true);
              Swal.fire({
                allowOutsideClick: false,
                icon: "success",
                title:
                  '<h5 style="color: #224d7c">Enlace de recupero de contraseña generado exitosamente.</h5>',
                text: res.data.message,
                color: "#224d7c",
                confirmButtonColor: "#224d7c",
                confirmButtonText: "Continuar",
              });
              fetchLogs({
                idLogType: 1,
                idType: 4,
                description:
                  "El usuario " +
                  res.data.data.user.email +
                  " ha pedido cambio de contraseña",
              });
            } else if (res.data.responseCode && res.data.responseCode == 400) {
              setLoader(false);
              SwalMessage(
                "warning",
                '<h5 style="color: #224d7c"></h5>',
                res.data.message,
                "Continuar"
              );
            } else {
              setLoader(false);
              SwalMessage(
                "warning",
                '<h5 style="color: #224d7c"></h5>',
                "Detectamos un error en la solicitud de recuperar contraseña, por favor intenta mas tarde o comunicate con nuestro equipo de soporte.",
                "Continuar"
              );
            }
          })
          .catch((error) => {
            setLoader(false);
            SwalMessage(
              "warning",
              '<h5 style="color: #224d7c"></h5>',
              "Detectamos un error en la solicitud de recuperar contraseña, por favor intenta mas tarde o comunicate con nuestro equipo de soporte.",
              "Continuar"
            );
          });
      }
    } else {
      if (
        specialCharacterValidation.test(password) &&
        upperCaseValidation.test(password) &&
        password.length > 7
      ) {
        if (password == "" || password2 == "") {
          setErrorMsg(
            "* Por favor ingresa las dos contraseñas para recuperar tu contraseña."
          );
        } else if (password !== password2) {
          setErrorMsg("* Las contraseñas ingresadas no coinciden.");
        } else if (emailValidator(email)) {
          setErrorMsg("* Email Invalido.");
        } else {
          setLoader(true);
          axios({
            url:
              process.env.REACT_APP_STRAPI_QA +
              process.env.REACT_APP_STRAPI_QA_ENDPOINT_PASSRECOVERY_UPDATE,
            method: "POST",
            data: body,
          })
            .then((res) => {
              if (res.data.responseCode && res.data.responseCode == 200) {
                setLoader(false);
                Swal.fire({
                  allowOutsideClick: false,
                  icon: "success",
                  title:
                    '<h5 style="color: #224d7c">Contraseña modificada con éxito</h5>',
                  text: res.data.message
                    ? res.data.message
                    : "Ya puedes iniciar sesión en tu portal HB Online.",
                  color: "#224d7c",
                  confirmButtonColor: "#224d7c",
                  confirmButtonText: "Continuar",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/");
                  }
                });
                fetchLogs({
                  idLogType: 1,
                  idType: 4,
                  description:
                    "El usuario " +
                    res.data.data.user +
                    " ha cambiado de contraseña",
                });
              } else if (
                res.data.responseCode &&
                res.data.responseCode == 400
              ) {
                if (
                  res.data.data.type &&
                  res.data.data.type == "InvalidPincode"
                ) {
                  setLoader(false);
                  setNewPinButton(true);
                  SwalMessage(
                    "warning",
                    '<h5 style="color: #224d7c"></h5>',
                    res.data.message,
                    "Continuar"
                  );
                } else {
                  setLoader(false);
                  SwalMessage(
                    "warning",
                    '<h5 style="color: #224d7c"></h5>',
                    res.data.message,
                    "Continuar"
                  );
                }
              } else {
                setLoader(false);
                SwalMessage(
                  "warning",
                  '<h5 style="color: #224d7c"></h5>',
                  "Detectamos un error en el proceso de recuperar contraseña; por favor comunicate con nuestro equipo de soporte.",
                  "Continuar"
                );
              }
            })
            .catch((error) => {
              setLoader(false);
              SwalMessage(
                "warning",
                '<h5 style="color: #224d7c"></h5>',
                "Detectamos un error en el proceso de recuperar contraseña. Por favor comunicate con nuestro equipo de soporte.",
                "Continuar"
              );
            });
        }
      } else {
        setErrorMsg(
          "* La contraseña debe contener minimo 8 caracteres, con al menos una mayuscula y un caracter especial."
        );
      }
    }
  };

  return (
    <>
      {loader ? <Overlay /> : null}
      <form onSubmit={handlerRecuperarContraseña}>
        <div className="d-flex align-items-center" style={{ height: "100vh" }}>
          <div className="div-login">
            <div className="d-flex flex-column align-items-center">
              <img className="img-logo" src={logoHB}></img>
              <div className="br"></div>
              <h5 className="default-fontColor">
                {checkMail !== ""
                  ? checkMail
                  : email
                  ? "Cambia tu Contraseña"
                  : "Recuperar Contraseña"}
              </h5>
              <div className="br"></div>
              {email ? (
                <input
                  value={email}
                  className="input-login shadow-blue input-login-v2"
                  disabled
                  placeholder="E-mail"
                ></input>
              ) : (
                <input
                  onChange={(e) => setInputMail(e.target.value)}
                  value={inputMail}
                  className="input-login shadow-blue input-login-v2"
                  placeholder="E-mail"
                ></input>
              )}
              <div className="br"></div>

              {!email ? (
                <>
                  {hideButton ? (
                    <></>
                  ) : (
                    <>
                      {loader ? (
                        <button
                          disabled={true}
                          type="submit"
                          className="button-loader shadow-blue mb-2 button-continuar"
                        >
                          <span className="spinner-grow spinner-grow-sm"></span>
                          <span className="spinner-grow spinner-grow-sm"></span>
                          <span className="spinner-grow spinner-grow-sm"></span>
                        </button>
                      ) : (
                        <>
                          <input
                            disabled={disabledButton}
                            className="button-continuar shadow-blue"
                            type="submit"
                            value="Continuar"
                          />
                        </>
                      )}
                      <div
                        className="button-outline-parent mt-2"
                        style={{ position: "relative" }}
                      >
                        <Link to={"/"} className="button-outline-login">
                          <div className="button-outline-login-contain"></div>
                          Volver al inicio
                        </Link>
                      </div>

                      {errorMsg ? <div className="br"></div> : null}
                      <p
                        style={{
                          color: "red",
                          fontWeight: "500",
                          maxWidth: "90%",
                          textAlign: "center",
                        }}
                      >
                        {errorMsg}
                      </p>
                    </>
                  )}
                </>
              ) : (
                <>
                  {/* <input  onChange={(e)=> setPassword(e.target.value)} value={password} type='password'
                                        className="input-login shadow-blue" placeholder="Contraseña"></input>  */}
                  <div
                    className="input-login shadow-blue  input-login-v2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      height: "42px",
                    }}
                  >
                    <input
                      style={{
                        width: "100%",
                        height: "40px",
                        marginLeft: "-8px",
                        paddingLeft: "10px",
                        borderRadius: "8px",
                      }}
                      className="custom-input-login"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      type={passwordShown ? "text" : "password"}
                      placeholder="Contraseña"
                    ></input>
                    <FontAwesomeIcon
                      style={{ right: "12px", position: "absolute" }}
                      icon={passwordShown ? faEyeSlash : faEye}
                      onClick={() => setPasswordShown((previus) => !previus)}
                    />
                  </div>
                  <div className="br"></div>
                  {/* <input
                                        onChange={(e)=> setPassword2(e.target.value)} value={password2} type='password'
                                        className="input-login shadow-blue" placeholder="Confirma tu Contraseña"></input>  */}
                  <div
                    className="input-login shadow-blue  input-login-v2"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                      height: "42px",
                    }}
                  >
                    <input
                      style={{
                        width: "100%",
                        height: "42px",
                        marginLeft: "-9px",
                        paddingLeft: "10px",
                      }}
                      className="custom-input-login"
                      onChange={(e) => setPassword2(e.target.value)}
                      value={password2}
                      type={passwordShown2 ? "text" : "password"}
                      placeholder="Confirmar contraseña"
                    ></input>
                    <FontAwesomeIcon
                      style={{ right: "12px", position: "absolute" }}
                      icon={passwordShown2 ? faEyeSlash : faEye}
                      onClick={() => setPasswordShown2((previus) => !previus)}
                    />
                  </div>
                  <div className="br"></div>

                  {loader ? (
                    <button
                      disabled={true}
                      type="submit"
                      className="button-loader shadow-blue button-continuar"
                    >
                      <span className="spinner-grow spinner-grow-sm"></span>
                      <span className="spinner-grow spinner-grow-sm"></span>
                      <span className="spinner-grow spinner-grow-sm"></span>
                    </button>
                  ) : (
                    <input
                      className="button-continuar shadow-blue"
                      type="submit"
                      value="Continuar"
                    />
                  )}
                  {errorMsg ? <div className="br"></div> : null}
                  <p
                    style={{
                      color: "red",
                      fontWeight: "500",
                      maxWidth: "90%",
                      textAlign: "center",
                    }}
                  >
                    {errorMsg}
                  </p>
                </>
              )}
              {/* <div className='br'></div> */}
              {checkMail !== "" ? (
                <Link
                  to={"/"}
                  className="button-continuar shadow-blue d-flex justify-content-center align-items-center"
                >
                  Iniciar Sesión
                </Link>
              ) : null}
            </div>
          </div>
          <div
            className={"bg-image"}
            style={{ backgroundImage: `url(${loadedBgImage || ""})` }}
          >
            <div className="bg-image-text">
              <div className="bg-image-text">
                <div className="transbox">
                  <>
                    <h1>Bienvenido a mi HB Online</h1>
                    <h5>
                      Solicitá un turno, accedé a tus resultados, gestioná tu
                      grupo familiar y tu perfil
                    </h5>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CommonPasswordRecovery;
