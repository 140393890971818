import "./index.scss";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import { myAxios } from "../../../utils/fetch";

const TurnosComponent = () => {
  const url = process.env.REACT_APP_GATEWAY_QA + "/bukeala/api/v1/token";
  const { user } = useContext(UserGlobalContextMemorySpace);
  const [fullUrl, setFullUrl] = useState();
  const [loading, setLoading] = useState(true);

  const gender = { M: "2", H: "1", null: "3" };
  const dnyType = { 0: "96", null: "NN" };

  useEffect(() => {
    const payload = {
      gender: gender[user.user.data.Customers.sex],
      identificationType: dnyType[user.user.data.Customers.dniType],
      identificationNumber: user.user.data.Customers.dni,
    };

    myAxios({
      url: url,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      data: payload,
    })
      .then((response) => {
        setTimeout(() => {
          setLoading(false);
        }, 13000);
        setFullUrl(response.data.data.bukeala.fullUrl);
      })
      .catch((exception) => {});
  }, []);

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    }
  }, [loading]);

  return (
    <>
      {loading && (
        <div className="spiner-container">
          <span className="spinner-grow spinner-grow-sm"></span>
          <span className="spinner-grow spinner-grow-sm"></span>
          <span className="spinner-grow spinner-grow-sm"></span>
        </div>
      )}
      <iframe
        title="Turnos"
        src={fullUrl}
        className={`iframe ${loading && "hide-iframe"}`}
        runat="server"
      />
    </>
  );
};

export default TurnosComponent;
