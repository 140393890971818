import React, { useState, useContext, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { TiDeleteOutline } from 'react-icons/ti';
import { ImCancelCircle } from 'react-icons/im';
import { BsCheckCircle } from 'react-icons/bs';
import style from './socialmediamodal-style.scss';
import { passportValidation } from '../../../utils/hooks/regex';
import { UserGlobalContextMemorySpace } from '../../../contexts/user/userGlobalContext';
import Swal from 'sweetalert2';
import { SwalMessage } from '../../../utils/sweet-alert/sweet-alert';
import { myAxios } from '../../../utils/fetch';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Calendar from 'react-calendar';


const SocialMediaModal = (props) => {

    // console.log("props1", props)

    const {socialMediaModal, setSocialMediaModal, sitePSHB, socialMediaData, setSocialMediaData} = useContext(UserGlobalContextMemorySpace);

    const navigate = useNavigate();

    const date = new Date();
    // const [maxDate, setMaxDate] = useState(`${date.getFullYear()}-${((date.getMonth()+1).toString() == 1 ? `0${date.getMonth()+1}` : `${date.getMonth()+1}`)}-${date.getUTCDate()}`)
    const [maxDate, setMaxDate] = useState(`${date.getFullYear()}-${((date.getMonth()+1).toString().length == 1 ? `0${date.getMonth()+1}` : `${date.getMonth()+1}`)}-${((date.getUTCDate()).toString().length == 1 ? `0${date.getUTCDate()}` : `${date.getMonth()}`)}`)
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [docTypePlaceholder, setDocTypPlaceholder] = useState("");
    const [documento, setDocumento] = useState("");
    const [docType, setDocType] = useState("");
    const [documentoPlaceholder, setDocumentoPlaceholder] = useState("");
    const [generoPlaceholder, setGeneroPlaceholder] = useState("");
    const [genero, setGenero] = useState("");
    const [fechaNac, setFechaNac] = useState("");
    const [fechaNacPlaceholder, setFechaNacPlaceholder] = useState("");
    const [dateType, setDateType] = useState("text");
    const [errorMsgModal, setErrorMsgModal] = useState("");
    const [casillaMail, setCasillaMail] = useState("");
    const [modalEmailForm, setModalEmailForm] = useState(true);
    const [modalUserNotFound, setModalUserNotFound] = useState(false);
    const [modalUserLinkInvitation, setModalUserLinkInvitation] = useState(false);
    const [modalUserNotValidated, setModalUserNotValidated] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);

    const modalOff = () =>{
        setModalEmailForm(false);
        setModalUserNotFound(false);
        setModalUserLinkInvitation(false);
        setModalUserNotValidated(false);
        setModalError(false);
    }

    const handlerHideModal = () =>{
        setShow(false)
        setSocialMediaModal(false)
        modalOff();
        setModalEmailForm(true);
    }

    const handleOnChangeDocType = (value) => {
        setDocType(value);
        setDocumento("");
    }


    const fullDate = (date) =>{

        
        const dia = date.getDate();
        const mes = date.getMonth() + 1;
        const anio = date.getFullYear();
        
        const fechaFormateada = `${dia.toString().padStart(2, '0')}-${mes.toString().padStart(2, '0')}-${anio}`;
        
        // console.log("data", fechaFormateada)
        setFechaNac(fechaFormateada)
        setShowCalendar(false);

        //handleClose()
    }

    const handlerAsociarCuenta = async (event) => {
        event.preventDefault();

        // console.log(documento, docType, fechaNac, genero)

        if(!documento || !docType || !fechaNac || !genero){
            setErrorMsgModal("* Por favor ingresa los datos requeridos.")
        }else{
            setErrorMsgModal("");
            setLoader(true);
            let formatDate = `${fechaNac.slice(-2)}-${fechaNac.slice(5,7)}-${fechaNac.slice(0,4)}`
                        
            // const auth = await axios({ url: process.env.REACT_APP_STRAPI_QA + '/external-auth/auth', method: 'POST' })
            // console.log(auth.token)

            // console.log("props", props)

            let newEmail = await socialMediaData.newEmail;
            let socialMedia = await socialMediaData.socialMedia;

            let body = {
                dni: documento,
                dniType: docType,
                gender: genero,
                birthday: fechaNac,
                newEmail: newEmail,
                socialMedia: socialMedia,
                pshb: false
              }
            await axios({            
                url: process.env.REACT_APP_STRAPI_QA+"/external-user/linksocialmediaemail",
                method: 'POST',      
                data: body            
            }).then((response)=>{       
                // console.log(response.data)
                // console.log(response.data.type)
                if(response.data.type && response.data.type == "accountLinkGenerated"){

                    let email = response.data.user;
                    let emailArr = email.split("@");
                    let firstTwoCharacters = emailArr[0].slice(0, 2);

                    setCasillaMail(`${firstTwoCharacters}********@${emailArr[1]}`)

                    modalOff();
                    setModalUserLinkInvitation(true);

                }else if(response.data.type && response.data.type == "UserNoExist"){
                    modalOff();
                    setModalUserNotFound(true);
                }else if(response.data.type && response.data.type == "UserNotValidated"){
                    modalOff();
                    setModalUserNotValidated(true);
                }else{
                    modalOff()
                    setModalError(true);
                }
            }).catch((error)=>{
                // console.log(error)
                modalOff()
                setModalError(true);
            })

            // console.log(res)

            setLoader(false)

        }

    }

    const handlerRegistrarse = () => {
        handlerHideModal();
        navigate('/register');
    }

    useEffect(()=>{

        setDocumento("");
        setDocType("");
        setGenero("");
        setFechaNac("");        

        socialMediaModal ? setShow(true) : setShow(false)
        
    }, [socialMediaModal])

    // useEffect(() => {
    //     setSocialMediaModal(true)
    // }, [])
    

    return (
        <>
        {/* <Button variant="primary" onClick={() => setShow(true)}>
            Custom Width Modal
        </Button> */}

        <Modal
            show={show}
            onHide={handlerHideModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {/* <button onClick={()=>{console.log(socialMediaData)}}>console</button> */}
            {/* <div className='d-flex justify-content-center' style={{width:"100%"}}>
                <p style={{fontSize: "20px", width: "50%", textAlign:"center"}}>
                    Asociá tu cuenta
                </p>
                <p onClick={handlerRegistrarse} style={{fontSize: "20px", width: "50%", textAlign:"center"}} className="button-not-active">
                    Registrarse
                </p>
            </div> */}

            {modalEmailForm ? 
                <>
                    {showCalendar? 
                    <>
                        <div style={{height: "80vh"}} className="d-flex align-items-center">
                            <div style={{height: "100%", width: "100%"}} className="d-flex align-items-center">
                                <div style={{ width: "100%", width:"100%"}}>
                                    <Modal.Body style={{paddingLeft: "5vh", paddingRight: "5vh"}}>
                                        <div className='asociar-cuenta d-flex flex-column justify-content-center'>
                                            <p style={{fontSize: "25px", textAlign:"center", width: "100%"}}>
                                                    {/* <ImCancelCircle size='40px' style={{color: "red", textAlign:"center"}}/> */}
                                            </p>
                                            <p style={{fontSize: "20px", textAlign:"center"}}>
                                                    Fecha de nacimiento
                                            </p>
                                            <p style={{fontSize: "20px", textAlign:"center"}} className="">
                                            </p>
                                            <div className='d-flex justify-content-center' style={{marginTop: "15px"}}>
                                                <div>
                                                    <Calendar 
                                                        minDate={new Date(`${date.getFullYear()-110}-01-01`)}
                                                        maxDate={date}     
                                                        onClickDay={(value, event) => fullDate(value)}
                                                        locale="es-ES"
                                                    />  
                                                </div>
                                            </div>
                                        </div>
                                        <div className='asociar-cuenta'>

                                        </div>
                                    </Modal.Body>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div style={{height: "80vh"}} className="d-flex align-items-center">
                            <div style={{height: "100%", width: "100%"}} className="d-flex align-items-center">
                                <div style={{ width: "100%", width:"100%"}}>
                                    <Modal.Body style={{paddingLeft: "5vh", paddingRight: "5vh"}}>
                                        <div className='asociar-cuenta d-flex flex-column justify-content-center'>
                                            <p style={{fontSize: "25px", textAlign:"center", width: "100%"}}>
                                                    <ImCancelCircle size='40px' style={{color: "red", textAlign:"center"}}/>
                                            </p>
                                            <p style={{fontSize: "20px", textAlign:"center"}}>
                                                    No tenemos registrado este mail.
                                            </p>
                                            <p style={{fontSize: "20px", textAlign:"center"}} className="">
                                                Validá tu identidad con los siguientes datos:
                                            </p>
                                            <form onSubmit={handlerAsociarCuenta}>
                                                <div className='d-flex flex-column justify-content-center' style={{marginTop: "15px"}}>
                                                    <div className='register-row-2'>
                                                        <select 
                                                            className={`option-register shadow-blue ${docTypePlaceholder}`}
                                                            onClick={()=>setDocTypPlaceholder("")} 
                                                            onChange={(e)=> handleOnChangeDocType(e.target.value)} 
                                                            value={docType}
                                                        >
                                                            <option className='option-default-size' value=""  hidden>Tipo de Documento</option>
                                                            <option value="0">DNI</option>                                                        
                                                            <option value="2">Pasaporte</option>                                                        
                                                        </select>
                                                        <div className='br'></div> 
                                                        <input 
                                                            maxLength={docType == "2" ? 20 : 8}
                                                            onChange={(e)=> docType == "2" ? setDocumento(passportValidation(e.target.value)) : setDocumento(e.target.value.replace(/[^0-9]/g, ''))} 
                                                            value={documento} className={`input-register shadow-blue ${documentoPlaceholder}`} placeholder="N° de Documento">
                                                        </input> 
                                                    </div>
                                                    <div className='register-row-2' style={{marginTop: "20px", marginBottom: "35px"}}>
                                                        <select 
                                                            className={`option-register shadow-blue ${generoPlaceholder}`}
                                                            onClick={()=>setGeneroPlaceholder("")} 
                                                            onChange={(e)=> setGenero(e.target.value)} 
                                                            value={genero}
                                                        >
                                                            <option className='option-default-size' value="" hidden>Género</option>
                                                            <option value="M">Mujer</option>
                                                            <option value="H">Hombre</option>
                                                        </select>
                                                        <div className='br'></div> 
                                                        {/* <input type={dateType} 
                                                            onFocus={()=>setDateType("date")}
                                                            // onBlur={()=>{
                                                            //     setDateType("text")
                                                            //     handlerDate()}
                                                            //     }
                                                            // data-date-format="DD-MM-YYYY"
                                                            value={fechaNac}
                                                            min="1900-01-01"
                                                            max={maxDate}
                                                            className={`input-register shadow-blue ${fechaNacPlaceholder}`} 
                                                            placeholder="Fecha de Nacimiento"
                                                            // pattern="\d{2}-\d{2}-\d{4}"
                                                            onChange={(e)=>setFechaNac(e.target.value)}>
                                                        </input> */}
                                                        <input
                                                            type="text"
                                                            onClick={() => {
                                                                setFechaNac("");
                                                                setShowCalendar(true);
                                                            }}
                                                            value={fechaNac}
                                                            min="1900-01-01"
                                                            max={maxDate}
                                                            className={`input-register shadow-blue ${fechaNacPlaceholder}`}
                                                            placeholder="Fecha de Nacimiento">
                                                        </input>
                                                    </div>
                                                    <div className='d-flex justify-content-center'>
                                                        {loader ?
                                                            <button disabled={true} type='submit' className="button-loader shadow-blue">
                                                                    <span className="spinner-grow spinner-grow-sm"></span>
                                                                    <span className="spinner-grow spinner-grow-sm"></span>
                                                                    <span className="spinner-grow spinner-grow-sm"></span>
                                                            </button>
                                                            :
                                                            <input className='button-continuar shadow-blue' type="submit" value="Continuar">
                                                                
                                                            </input>
                                                        }
                                                    </div>
                                                    <p style={{color: "red", fontWeight:"500", marginTop:"30px", textAlign:"center"}}>{errorMsgModal}</p>
                                                </div>
                                            </form>
                                        </div>
                                        <div className='asociar-cuenta'>

                                        </div>
                                    </Modal.Body>
                                </div>
                            </div>
                        </div>
                    </>
                    }
                </> 
                : 
                <></>
            }

            {modalUserNotFound ? 
                <>
                    <div style={{height: "80vh"}}>
                        <Modal.Body style={{height: "100%", paddingLeft: "5vh", paddingRight: "5vh"}}>
                            <div className='asociar-cuenta d-flex flex-column justify-content-center' style={{height: "100%"}}>
                                <p style={{fontSize: "20px", textAlign:"center"}} className="">
                                    <ImCancelCircle size='40px' style={{color: "red", textAlign:"center"}}/>
                                </p>
                                <p style={{fontSize: "25px", textAlign:"center"}}>
                                    Los datos ingresados no pertenecen a un usuario registrado.
                                </p>
                                <p style={{fontSize: "25px", textAlign:"center"}}>
                                    Podés volver a intentar o registrarte.
                                </p>
                                <div className='d-flex justify-content-center'>
                                    <button onClick={handlerHideModal} className="button-loader-white shadow-blue" style={{width: "140px", marginRight: "30px"}}>
                                        Volver
                                    </button>
                                    <button onClick={handlerRegistrarse} className="button-loader shadow-blue" style={{width: "140px"}}>
                                        Registrarse
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </div>
                
                </> 
                : 
                <></>
            }

            {modalUserLinkInvitation ? 
                <>
                    <div style={{height: "80vh"}}>
                        <Modal.Body style={{height: "100%", paddingLeft: "5vh", paddingRight: "5vh"}}>
                            <div className='asociar-cuenta d-flex flex-column justify-content-center' style={{height: "100%"}}>
                                <p style={{fontSize: "20px", textAlign:"center"}} className="">
                                    <BsCheckCircle size='40px' style={{color: "green", textAlign:"center", fontWeight: "800"}}/>
                                </p>
                                <p style={{fontSize: "25px", textAlign:"center"}}>
                                    Enviamos un mail a {casillaMail} para validar tu identidad.
                                </p>
                                <div className='d-flex justify-content-center'>
                                    <button onClick={handlerHideModal} className="button-loader shadow-blue">
                                        Volver
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </div>
                </> 
                : 
                <></>
            }

            {modalUserNotValidated ? 
                <>
                    <div style={{height: "80vh"}}>
                        <Modal.Body style={{height: "100%", paddingLeft: "5vh", paddingRight: "5vh"}}>
                            <div className='asociar-cuenta d-flex flex-column justify-content-center' style={{height: "100%"}}>
                                <p style={{fontSize: "20px", textAlign:"center"}} className="">
                                    <ImCancelCircle size='40px' style={{color: "red", textAlign:"center"}}/>
                                </p>
                                <p style={{fontSize: "25px", textAlign:"center"}}>
                                    Tu usuario no se encuentra validado. Para continuar con el proceso de vinculación de cuentas, primero validá tu usuario.
                                </p>
                                <div className='d-flex justify-content-center'>
                                    <button onClick={handlerHideModal} className="button-loader shadow-blue" style={{width: "140px", marginRight: "30px"}}>
                                        Volver
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </div>
                </> 
                : 
                <></>
            }

            {modalError ? 
                <>
                    <div style={{height: "80vh"}}>
                        <Modal.Body style={{height: "100%", paddingLeft: "5vh", paddingRight: "5vh"}}>
                            <div className='asociar-cuenta d-flex flex-column justify-content-center' style={{height: "100%"}}>
                                <p style={{fontSize: "20px", textAlign:"center"}} className="">
                                    <ImCancelCircle size='40px' style={{color: "red", textAlign:"center"}}/>
                                </p>
                                <p style={{fontSize: "25px", textAlign:"center"}}>
                                    Ocurrió un error en la valición. Por favor intenta nuevamente en breves minutos.
                                </p>
                                <div className='d-flex justify-content-center'>
                                    <button onClick={handlerHideModal} className="button-loader shadow-blue" style={{width: "140px", marginRight: "30px"}}>
                                        Volver
                                    </button>
                                </div>
                            </div>
                        </Modal.Body>
                    </div>
                </> 
                : 
                <></>
            }
        </Modal>
        </>
  );
}

export default SocialMediaModal;
