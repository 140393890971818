import React, { useContext } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import ErrorIcon from "../../../assets/img/error-icon.png";
import SuccessIcon from "../../../assets/img/success-icon.png";
import { MessageContext } from "../../../contexts/message/messageContext";

import "./style.scss";
export const Message = ({ visible = false, onToggle, success = true }) => {
  const responseData = useContext(MessageContext);

  return (
    <Modal
      size={"md"}
      aria-labelledby="contained-modal-title-vcenter"
      show={visible}
      centered
      onHide={onToggle}
    >
      <Modal.Body
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={!responseData.error ? SuccessIcon : ErrorIcon}
          alt="Message"
          style={{ width: "50px" }}
        />
        <div className="header-familygroup-information mt-2">
          <span className="titulo-eliminar-familiar text-center">
            {responseData.message}
          </span>
        </div>

        <div className="politica-proteccion">
          <div className="section-edit-information">
            <section>
              <Row>
                <Col md={12}>
                  {!responseData?.hideVolver ? (
                    <div className="row mt-4">
                      <div
                        className="col-md-12 family-add-buttons"
                        style={{ paddingBottom: "10px" }}
                      >
                        <div
                          className="btn btn-hb-primary-without-border"
                          onClick={() => onToggle()}
                        >
                          Volver
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </section>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
