import React, { useContext, useState } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import style from "./style.scss";
import Swal from "sweetalert2";
import { SwalMessage } from "../../../utils/sweet-alert/sweet-alert";
import axios from "axios";
import { myAxios } from "../../../utils/fetch";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { InformacionComponent as InformacionComponentPSHB } from "./informacionPSHB";
import { InformacionComponent as InformacionComponentHB } from "./informacionHB";
import { useToggle } from "../../../hooks/useToggle";
import ReactGA from "react-ga4";
import InformationIcon from "../../../assets/img/information.svg";
import { useNavigate, useSearchParams } from "react-router-dom";

const CommonRecuperoContrasena = (props) => {
  const { sitePSHB, setSitePSHB, userPSHB, setUserPSHB, user, setUser } =
    useContext(UserGlobalContextMemorySpace);
  const [loader, setLoader] = useState(false);
  const [hideButton, setHideButton] = useState(false);
  const infoToggle = useToggle();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({});
  const openDialogParams = searchParams.get("open");

  React.useEffect(() => {
    if (openDialogParams == "1") {
      infoToggle.onToggle();
    }
  }, []);

  const handlerRecuperarContraseña = () => {
    setLoader(true);
    axios({
      url:
        process.env.REACT_APP_STRAPI_QA +
        process.env.REACT_APP_STRAPI_QA_ENDPOINT_PASSRECOVERY,
      method: "POST",
      data: {
        email: user.user.data.Customers.email,
        pshb: false,
        typeEmail: "EMAIL_BODY_CHANGEPASSWORD",
      },
    })
      .then((res) => {
        if (res.data.responseCode && res.data.responseCode == 200) {
          setHideButton(true);
          setLoader(false);
          setHideButton(true);
          Swal.fire({
            allowOutsideClick: false,
            icon: "success",
            title:
              '<h5 style="color: #224d7c">Enlace de cambio de contraseña generado exitosamente.</h5>',
            text: res.data.message,
            color: "#224d7c",
            confirmButtonColor: "#224d7c",
            confirmButtonText: "Continuar",
          }).then((result) => {
            navigate("/cerrar-sesion");
          });
        } else if (res.data.responseCode && res.data.responseCode == 400) {
          setLoader(false);
          SwalMessage(
            "warning",
            '<h5 style="color: #224d7c"></h5>',
            res.data.message,
            "Continuar"
          );
        } else {
          setLoader(false);
          SwalMessage(
            "warning",
            '<h5 style="color: #224d7c"></h5>',
            "Detectamos un error en la solicitud de recuperar contraseña, por favor intenta mas tarde o comunicate con nuestro equipo de soporte.",
            "Continuar"
          );
        }
      })
      .catch((error) => {
        setLoader(false);
        SwalMessage(
          "warning",
          '<h5 style="color: #224d7c"></h5>',
          "Detectamos un error en la solicitud de recuperar contraseña, por favor intenta mas tarde o comunicate con nuestro equipo de soporte.",
          "Continuar"
        );
      });
  };

  const informacion = () => {
    infoToggle.onToggle();
    ReactGA.event({
      category: `Acciones-GrupoFamiliar-${userPSHB ? "PSHB" : "HB"}`,
      action: "Click",
      label: "information",
    });
  };

  return (
    <section className="">
      <div className="container">
        <div className="row" id="contenedor-cambio-contrasena">
          <div
            className={
              "recupero-contrasena-card " +
              (userPSHB
                ? "recupero-contrasena-card-PSHB"
                : "recupero-contrasena-card-HB")
            }
            style={
              userPSHB
                ? { borderLeft: "20px solid #9fcc4b" }
                : { borderLeft: "20px solid red" }
            }
          >
            <div className="d-flex flex-column recupero-contrasena-cardbox ">
              <div style={{ height: "10%", width: "100%" }}>
                {/* <div className="d-flex justify-content-center">
                  
                  <a data-tooltip-id="my-tooltip" data-tooltip-content="Ayuda">
                    <ReactTooltip id="my-tooltip" />{" "}
                    <img
                      id="icon-information"
                      onClick={() => informacion()}
                      src={InformationIcon}
                      alt="..."
                    ></img>
                  </a>
                  {userPSHB ? (
                    <InformacionComponentPSHB
                      visible={infoToggle.toggle}
                      onToggle={infoToggle.onToggle}
                    />
                  ) : (
                    <></>
                  )}
                </div> */}
                <div className="row">
                  <div
                    style={{
                      alignItems: "center",
                      paddingBottom: "5px",
                    }}
                    className="col-lg-12"
                    id="header-invoice"
                  >
                    {/* <span className="section_pshb_subtitle">
                      Desde esta opción puede observar a todo su grupo familiar
                      activo y el estado en que se encuentra cada uno.
                    </span> */}
                    <h2
                      className="font-color-PSHB titulo"
                      style={{ margin: "0 auto" }}
                    >
                      Cambio de contraseña
                    </h2>
                    <a
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content="Ayuda"
                    >
                      <ReactTooltip id="my-tooltip" />{" "}
                      <img
                        id="icon-information"
                        onClick={() => informacion()}
                        src={InformationIcon}
                        alt="..."
                        style={{ height: "28px" }}
                      ></img>
                    </a>
                    {userPSHB ? (
                      <InformacionComponentPSHB
                        visible={infoToggle.toggle}
                        onToggle={infoToggle.onToggle}
                      />
                    ) : (
                      <InformacionComponentHB
                        visible={infoToggle.toggle}
                        onToggle={infoToggle.onToggle}
                      />
                    )}
                  </div>
                  <hr
                    style={
                      userPSHB
                        ? {
                            border: "3px solid #9fcc4b",
                            width: "100%",
                            opacity: "1",
                          }
                        : {
                            border: "2px solid red",
                            opacity: "20",
                            width: "100%",
                          }
                    }
                  />
                </div>
              </div>
              <div
                style={{ marginTop: "20px" }}
                className="d-flex justify-content-center align-items-center"
              >
                <div className="d-block justify-content-center">
                  <h4 className="introducion-cambio-contraseña-HB subtitulo-cambio">
                    Al hacer click en continuar, vas a recibir un mail con
                    instrucciones que te permitirán realizar el cambio de
                    contraseña para acceder al portal web del Hospital
                    Británico.
                  </h4>
                  <h5 className="texto-cambio-contraseña-HB subtitulo-cambio">
                    Presioná el botón continuar para recibir un mail con los
                    pasos a seguir para el cambio de contraseña.
                  </h5>
                </div>
              </div>
              <div style={{ height: "10%" }}>
                <p className="texto-input-cambio-contraseña-HB"> </p>
                <div
                  className="recupero-contrasena-input"
                  style={
                    userPSHB
                      ? { border: "2px solid #9fcc4b" }
                      : { border: "2px solid red" }
                  }
                >
                  <i
                    className="bi bi-envelope"
                    style={
                      userPSHB
                        ? { color: "#9fcc4b", fontSize: "23px" }
                        : { color: "red", fontSize: "23px" }
                    }
                  ></i>
                  <button className="email-cambiar" disabled>
                    {user.user.data.Customers.email}{" "}
                  </button>
                </div>
                {hideButton ? (
                  <></>
                ) : (
                  <>
                    {loader ? (
                      <>
                        <div
                          className="d-flex justify-content-center"
                          style={{ marginTop: "50px" }}
                        >
                          <button
                            disabled={true}
                            type="submit"
                            className="button-loader shadow-blue"
                          >
                            <span className="spinner-grow spinner-grow-sm"></span>
                            <span className="spinner-grow spinner-grow-sm"></span>
                            <span className="spinner-grow spinner-grow-sm"></span>
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="d-flex justify-content-center"
                          style={{ marginTop: "50px" }}
                        >
                          <button
                            onClick={handlerRecuperarContraseña}
                            className="button-continuar button-continuar-recuperar"
                          >
                            Continuar
                          </button>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CommonRecuperoContrasena;
