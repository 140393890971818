import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import UserSidebar from "../../../components/common/user-sidebar";
import AyudaComponent from "../../../components/hb/ayuda";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import style from "./style.scss";

const AyudaHB = () => {

  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <AyudaComponent ref={ref}></AyudaComponent>
    </div>
  );
};

export default AyudaHB;