import * as Yup from "yup";

export const validateModel = Yup.object().shape({
  desde: Yup.string().test("match", "Fecha inválida", function (value) {
    if (!value) {
      return false;
    }

    const vigenteDesde = value.split("/");
    const today2 = new Date();
    const date2 = new Date(today2);
    date2.setMonth(vigenteDesde[0] - 1);
    date2.setFullYear(vigenteDesde[1]);

    return !(date2 < today2);
  }),
  hasta: Yup.string().test("match", "Fecha inválida", function (value) {
    if (!value) {
      return false;
    }

    const vigenteDesde = value.split("/");
    const today2 = new Date();
    const date2 = new Date(today2);
    date2.setMonth(vigenteDesde[0] - 1);
    date2.setFullYear(vigenteDesde[1]);

    return !(date2 < today2);
  }),
});
