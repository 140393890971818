import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./style.scss";

export const InvitcionPSHBComponent = ({ visible = false, onToggle }) => {  


  const ContenidoPolitica=()=>{
    return (
      <div className="contenido-invitacion-pshb">
        <span className="informacion-invitacion-pshb">Para conocer los beneficios de Plan de Salud del Hospital Británico, contáctese con un asesor comercial desde <a target="_blank" rel="noreferrer" href="https://promociones.hbritanicoweb.com.ar/ ">aquí</a></span>
      </div>
    )
  } 

  return (
    <Modal
      size={"md"}
      aria-labelledby="contained-modal-title-vcenter"
      show={visible}
      centered
      onHide={onToggle}
    >
      <Modal.Header closeButton className="custom-header" style={{height:"20px"}}>
        <Modal.Title
          className={"titulo-proteccion"}
        >          
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>        
          <ContenidoPolitica />        
      </Modal.Body>    
    </Modal>
  );
};
