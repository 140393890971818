import React, { useState } from "react";
import "./style.scss";
import logo from "../../../assets/img/logos/logo-hospital.png";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { Modal } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

import Overlay from "../../../components/common/overlay";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CommonDeleteAccount = (props) => {
  const captcha = React.useRef(null);
  const [loader, setLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = React.useState(false);

  const [passwordShown, setPasswordShown] = useState(false);

  const handleDeleteAccount = async () => {
    setLoader(true);
    setVisible(false);

    await axios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/customers/api/v1/account/accountdeletion",
      method: "POST",
      data: {
        email,
        password,
        typeEmail: "EMAIL_BODY_ACCOUNT_DELETED",
      },
    })
      .then((res) => {
        if (!res.data.error) {
          Swal.fire({
            allowOutsideClick: false,
            title:
              '<h5 style="color: #224d7c">Cuenta desactivada exitosamente</h5>',
            showDenyButton: false,
            showCancelButton: false,
            color: "#224d7c",
            confirmButtonColor: "#224d7c",
            confirmButtonText: "Continuar",
            text: "Te enviamos un mail a tu casilla confirmando la desactivación de tu usuario. ¡Hasta pronto!",
            // denyButtonText: `Don't save`,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.href = "/";
            }
          });
        } else {
          if (res.data.data.type == "UserNotValidated") {
            SwalMessage(
              false,
              "warning",
              '<h5 style="color: #224d7c">Usuario no validado</h5>',
              res.data.message
            );
          } else if (res.data.data.type == "UserBlocked") {
            SwalMessage(
              false,
              "warning",
              '<h5 style="color: #224d7c">Usuario Bloqueado</h5>',
              res.data.message
            );
          } else if (res.data.data.type == "AccountDeleted") {
            SwalMessage(
              false,
              "warning",
              '<h5 style="color: #224d7c">Usuario Desactivado</h5>',
              res.data.message
            );
          } else if (res.data.data.type == "UserLoginError") {
            SwalMessage(
              false,
              "warning",
              '<h5 style="color: #224d7c">Contraseña Incorrecta</h5>',
              "Despues de 5 intentos incorrectos se bloquea el usuario"
            );
          } else if (res.data.data.type == "UserNoExist") {
            SwalMessage(
              false,
              "warning",
              `<h5 style="color: #224d7c">${res.data.message}</h5>`,
              ""
            );
          } else {
            SwalMessage(
              false,
              "warning",
              '<h5 style="color: #224d7c">Ha ocurrido un error inésperado, espera unos momentos y vuelve a intentarlo</h5>',
              res.data.message
            );
          }
        }
      })
      .catch((error) => {
        SwalMessage(
          false,
          "warning",
          '<h5 style="color: #224d7c">Error de conexión</h5>',
          "Ha ocurrido un error inésperado, espera unos momentos y vuelve a intentarlo."
        );
      });
    setLoader(false);
  };

  const SwalMessage = (allowOutsideClick, icon, title, text) => {
    Swal.fire({
      allowOutsideClick: allowOutsideClick,
      icon: icon,
      title: title,
      text: text,
      color: "#224d7c",
      confirmButtonColor: "#224d7c",
      confirmButtonText: "Continuar",
    });
  };

  const eliminar = (event) => {
    event.preventDefault();
    if (!password) {
      SwalMessage(
        false,
        "info",
        '<h5 style="color: #224d7c">Por favor ingresa una contraseña.</h5>',
        ""
      );
    } else {
      if (captcha.current?.getValue()) {
        setVisible(true);
        captcha.current?.reset();
      } else {
        SwalMessage(
          false,
          "info",
          '<h5 style="color: #224d7c">Debe indicar que no es un robot.</h5>',
          ""
        );
      }
    }
  };

  return (
    <>
      {loader ? <Overlay /> : null}
      <form onSubmit={eliminar}>
        <div className="div-login2">
          <div className="d-flex flex-column align-items-center">
            <img className="img-logo" src={logo}></img>
            <div className="br"></div>
            <h5 className="default-fontColor">Desactivar Cuenta</h5>
            <div className="br"></div>
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="input-login shadow-blue input-login-v22"
              placeholder="E-mail"
            ></input>

            <div className="br"></div>

            <div
              className="input-login shadow-blue input-login-v22"
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                paddingLeft: "0px",
                paddingRight: "0px",
                height: "42px",
              }}
            >
              <input
                style={{
                  width: "100%",
                  height: "100%",
                  marginLeft: "-8px",
                  paddingLeft: "10px",
                  borderRadius: "8px",
                }}
                className="custom-input-login"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                type={passwordShown ? "text" : "password"}
                placeholder="Contraseña"
              ></input>
              <FontAwesomeIcon
                style={{ right: "12px", position: "absolute" }}
                icon={passwordShown ? faEyeSlash : faEye}
                onClick={() => setPasswordShown((previus) => !previus)}
              />
            </div>

            <div className="br"></div>

            <p className="texto-desactivar">
              En esta sección, tenés la posibilidad de desactivar tu cuenta. No
              se eliminará tu casilla de mail y datos personales, ya que de
              conformidad con el artículo N° 8 de la Ley 25.326 y artículo 17 de
              la Ley 26.529, como institución de la Salud debemos garantizar el
              resguardo de tu historial clínico, y estos datos forman parte del
              mismo. En caso de que necesites volver a iniciar sesión con tus
              datos, comunicate con nuestro equipo de{" "}
              <a href="mailto:soportewebhb@hbritanico.com.ar">Soporte Web</a>{" "}
              para reactivar tu cuenta.
            </p>

            <div className="br"></div>

            <div
              className="button-outline-parent mt-2"
              style={{ position: "relative" }}
            >
              <Link to={"/"} className="button-outline-login">
                <div className="button-outline-login-contain"></div>
                Volver al inicio
              </Link>
            </div>

            <div className="d-flex justify-content-between align-items-center hr-box mt-4">
              <div>
                <hr className="hr-login" />
              </div>
              <div className="dot"></div>
              <div>
                <hr className="hr-login" />
              </div>
            </div>

            <div className="br"></div>

            {loader ? (
              <button
                disabled={true}
                type="submit"
                className="button-loader shadow-blue"
              >
                <span className="spinner-grow spinner-grow-sm"></span>
                <span className="spinner-grow spinner-grow-sm"></span>
                <span className="spinner-grow spinner-grow-sm"></span>
              </button>
            ) : (
              <>
                <div className="mb-4 captcha">
                  <div className="row">
                    <div className="col">
                      <div id="botCaptcha">
                        <ReCAPTCHA
                          ref={captcha}
                          sitekey={process.env.REACT_APP_CAPTCHA}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  disabled={buttonDisabled}
                  className="button-continuar shadow-blue"
                  type="submit"
                  value="Desactivar"
                />
              </>
            )}
          </div>
        </div>
      </form>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        show={visible}
        centered
        // key={data}
      >
        <Modal.Body
          style={{
            paddingBottom: "0",
            marginBottom: "0",
          }}
        >
          <div className="modalBody">
            <div className="row mt-2">
              <div className="col-lg-12 ">
                <h2 className="section_pshb_title">
                  <center>
                    <b>¿Estás seguro que querés desactivar tu cuenta?</b>
                  </center>
                </h2>
              </div>
            </div>
            <div className="separador-table-red-result"></div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <div
            style={{
              paddingTop: "0",
            }}
            className="mx-auto"
          >
            <button
              style={{
                marginTop: "0",
              }}
              className="boton-volver"
              onClick={() => {
                setVisible(false);
              }}
            >
              Cancelar
            </button>
            <button
              style={{
                marginTop: "0",
              }}
              className="boton-confirmar"
              onClick={() => handleDeleteAccount()}
            >
              Aceptar
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CommonDeleteAccount;
