import React from 'react';
import { Button, Modal,Row,Col } from 'react-bootstrap';
import { CUSTOM_SELECT_COLOR_WHITE } from '../../../common/customSelect/constants';
import CustomSelect from "../../../common/customSelect";
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



import './eliminar-familiar.scss'
export const EliminarFamiliarComponent = ({
    visible = false,
    onToggle,
}) => {

    const [isAddFamily,setAddFamily]=React.useState(false);  
    const [disabled,setDisabled]=React.useState(true);
    

    return (
        <Modal
            size={"md"}
            aria-labelledby="contained-modal-title-vcenter"
            show={visible}
            centered
            onHide={onToggle}
        >
            {/* <Modal.Header  className='custom-header'>                            
            </Modal.Header> */}

            <Modal.Body>            
            <div className='header-familygroup-information mt-2'>
                <span className='titulo-eliminar-familiar text-center'>¿Estás seguro que querés eliminar a esta persona de tu Grupo Familiar?</span>                                                                          
            </div>                                    

                <div style={{display:"flex",justifyContent:"center",width:"100%"}}>
                    <div className='barra-eliminar-familiar'>

                    </div>  
                </div>
                <div className='politica-proteccion'>                    
                    <div className='section-edit-information'>                                                
                    <section>
            <Row>
                <Col md={12}>                                           
                    <div className="row mt-4">
                        <div className="col-md-12 family-add-buttons" style={{paddingBottom:"10px"}}>
                            <div className="btn btn-hb-secondary-without-border" onClick={() => onToggle()}>Cancelar</div>
                            <div className="btn btn-hb-primary-without-border" onClick={() => setDisabled(false)}>Aceptar</div>
                        </div>
                    </div>     
                </Col>                
            </Row>           
            </section>                     
                    </div>                    
                </div>                
            </Modal.Body>
            {/* <Modal.Footer>                
                <Button className='btn2-color3-r' variant="primary" onClick={()=>onToggle()}>Cerrar</Button>
            </Modal.Footer> */}

        </Modal>
    )
}
