import React, { useContext, useLayoutEffect, useRef } from "react";
import ViewIcon from "../../../assets/img/card-icons/previsualizar.svg";
import MailIcon from "../../../assets/img/card-icons/mail.svg";

import DownloadIcon from "../../../assets/img/card-icons/descargar.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./results.scss";
import CustomSelect from "../../common/customSelect";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import {
  CUSTOM_SELECT_COLOR_BLUE,
  CUSTOM_SELECT_COLOR_WHITE,
  CUSTOM_SELECT_COLOR_RED,
} from "../../common/customSelect/constants";

import ReactGA from "react-ga4";

import { useToggle } from "../../../hooks/useToggle";
import {
  ModalEmailComponent as EmailComponentSuccess,
  ModalEmailComponent as EmailComponentError,
} from "./email";
import { fetchLogs, myAxios } from "../../../utils/fetch";
import { TarjetaResultadosComponent } from "./tarjetaResultadosComponent";

import { SwalMessage } from "../../../utils/sweet-alert/sweet-alert";

const ResultsComponent = () => {
  const { user } = useContext(UserGlobalContextMemorySpace);
  const [familyGroupList, setFamilyGroupList] = React.useState(null);
  const [year, setYear] = React.useState("Todos");
  const [limit, setLimit] = React.useState(5);
  const [offset, setOffset] = React.useState(0);
  const [service, setService] = React.useState("all");
  const [idSap, setIdSap] = React.useState(user.user.data.Customers.idSap);
  const [customers, setCustomers] = React.useState([user.user.data.Customers]);
  const [results, setResults] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [loader, setLoader] = React.useState(true);
  const [familyItem, setFamilyItem] = React.useState(
    user.user.data.Customers.firstName + " " + user.user.data.Customers.lastName
  );
  const [serviceSelected, setServiceSelected] = React.useState(
    "Todos los resultados"
  );
  const [controllerAxios, setControllerAxios] = React.useState();

  const [closeAno, setCloseAno] = React.useState(false);
  const [closeFamily, setCloseFamily] = React.useState(false);
  const [closeService, setCloseService] = React.useState(false);

  const emailToggleSuccess = useToggle();
  const emailToggleError = useToggle();

  const refBusqueda = useRef();

  // useLayoutEffect(() => {
  //   fetchLogs({
  //     idLogType: 1,
  //     idType: 18,
  //     description:
  //       "El usuario " +
  //       user.user.data.Customers.lastName +
  //       " " +
  //       user.user.data.Customers.firstName +
  //       " accedió al área de laboratorio",
  //   });
  // }, []);

  const obtenerResultados = (
    param_idSap,
    param_limit,
    param_offset,
    param_service,
    param_search,
    param_year
  ) => {
    setResults([]);
    setLoader(true);
    const payLoad = {
      dni: user.user.data.Customers.dni,
      email: user.user.data.Customers.email,
      idSap: param_idSap,
      limit: param_limit,
      offset: param_offset,
      service: param_service,
      search: param_search.trim(),
      idCustomer: user.user.data.Customers.idCustomer,
    };

    if (param_year !== "Todos") {
      payLoad.year = param_year;
    }

    let controller = new AbortController();
    setControllerAxios(controller);

    let endpoint =
      payLoad.service == "Otros Informes"
        ? "/patient/api/v1/results/laboratoryresultsother"
        : payLoad.service == "Medicina Nuclear"
        ? "/patient/api/v1/results/laboratoryresultsimg"
        : payLoad.service == "Diagnóstico por Imágenes"
        ? "/patient/api/v1/results/laboratoryresultsimg"
        : payLoad.service == "Laboratorio"
        ? "/patient/api/v1/results/laboratoryresultslab"
        : "/patient/api/v1/results/laboratoryresultsall";

    if (payLoad.service === "Medicina Nuclear") {
      setTotal(0);
      setLoader(false);
      setResults([]);
      return;
    }

    myAxios({
      signal: controller.signal,
      url: `${process.env.REACT_APP_GATEWAY_QA + endpoint}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      data: payLoad,
    })
      .then((response) => {
        setTotal(0);
        setLoader(false);
        setResults(response.data.data.Results);
        if (response.data.data.total > 0) {
          setTotal(response.data.data.total);
        }
      })
      .catch((error) => {
        setResults([]);
        setLoader(false)
        // console.log("ERRRRR",error);
      })
      .finally(() => {
        setLoader(false)
      });
  };

  const onChangeYear = (id, name, text) => {
    setYear(id);
    setOffset(0);
    obtenerResultados(idSap, limit, 0, service, search, id);
  };

  const clickYear = () => {
    ReactGA.event({
      category: "Acciones-Resultados",
      action: "click",
      label: "anio-estudios",
    });
    setCloseFamily(true);
    setCloseService(true);
    setCloseAno(false);
  };

  React.useEffect(() => {
    myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/customers/api/v1/familiargroup/getfamiliargroupbyid",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      data: {
        idCustomer: user.user.data.Customers.idCustomer,
      },
    })
      .then((response) => {
        const familyGroup = response.data.data.FamilyGroup.map((family) => {
          return { id: family.idSap ?? "", name: family.fullName };
        });
        var temporalFamilyGroup = [
          {
            id: user.user.data.Customers.idSap,
            name:
              user.user.data.Customers.firstName +
              " " +
              user.user.data.Customers.lastName,
          },
          ...familyGroup,
        ];
        setCustomers([...customers, ...response.data.data.FamilyGroup]);
        setFamilyGroupList(temporalFamilyGroup);
      })
      .catch((error) => {})
      .finally(() => {
        obtenerResultados(idSap, limit, offset, service, search, year);
      });
  }, []);

  React.useEffect(() => {}, [service, year, idSap, search, offset]);

  React.useEffect(() => {
    setOffset(0);
  }, [service, year, idSap, search]);

  //var defaultSelectText="Todos los resultados";
  var estudiosList = [
    { id: "all", name: "Todos los resultados" },
    { id: "Diagnóstico por Imágenes", name: "Diagnóstico por Imágenes" },
    { id: "Laboratorio", name: "Laboratorio" },
    { id: "Medicina Nuclear", name: "Medicina Nuclear" },
    { id: "Otros Informes", name: "Otros Informes" },
  ];

  const yearsList = (() => {
    const now = new Date();
    const year = now.getFullYear();
    const listaAnos = [{ id: "Todos", name: "Todos" }];
    const anos = year - 2017;

    for (var i = 0; i <= anos; i++) {
      listaAnos.push({ id: year - i, name: year - i });
    }

    return listaAnos;
  })();

  const changeService = (id, name, text) => {
    setService(id);
    setServiceSelected(text);
    setOffset(0);
    obtenerResultados(idSap, limit, 0, id, search, year);
  };

  const clickService = () => {
    ReactGA.event({
      category: "Acciones-Resultados",
      action: "click",
      label: "resultados-all",
    });
    setCloseFamily(true);
    setCloseService(false);
    setCloseAno(true);
  };

  const changeidSap = (id, name, text) => {
    setFamilyItem(text);
    setIdSap(id);
    setOffset(0);
    obtenerResultados(id, limit, 0, service, search, year);
  };

  const clickIdSap = () => {
    setCloseFamily(false);
    setCloseService(true);
    setCloseAno(true);
  };

  const searchData = (event) => {
    ReactGA.event({
      category: "Acciones-Resultados",
      action: "click",
      label: "Buscar",
    });

    let buscar = event.target.value;

    setSearch(buscar);
    setLoader(true);
    controllerAxios?.abort();
    setOffset(0);
    obtenerResultados(idSap, limit, 0, service, event.target.value, year);
  };

  const siguiente = () => {
    if (offset + limit > total) return;
    setOffset(offset + limit);
    obtenerResultados(idSap, limit, offset + limit, service, search, year);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // Para un desplazamiento suave, si es compatible con el navegador
    });
  };

  const anterior = () => {
    const temporalOffset = offset - limit;
    if (temporalOffset < 0) return;
    setOffset(temporalOffset);
    obtenerResultados(idSap, limit, temporalOffset, service, search, year);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const descargar = (resultado) => {
    if (resultado.idReport != null) {
      window.open(resultado.links.urlDownload);
    } else {
      myAxios({
        url: resultado.links.urlDownload,
        headers: {
          Authorization: `Bearer ${user.user.data.appToken}`,
        },
      })
        .then((response) => {
          if (!response?.data?.data?.File) {
            SwalMessage(
              "info",
              '<h5 style="color: #224d7c"></h5>',
              "Disculpe, no se pudo descargar el estudio. Por favor, intente nuevamente más tarde.",
              "Continuar"
            );
            return;
          }

          // fetchLogs({
          //   idLogType: 1,
          //   idType: 7,
          //   description:
          //     "El usuario " +
          //     user.user.data.Customers.lastName +
          //     " " +
          //     user.user.data.Customers.firstName +
          //     " bajó el informe resultado_" +
          //     resultado.idLaboratory,
          // });

          const isSafariOnIOS = () => {
            const userAgent = navigator.userAgent;
            return (
              /iPhone|iPad|iPod/.test(userAgent) &&
              !/CriOS/.test(userAgent) &&
              /Safari/.test(userAgent)
            );
          };

          if (isSafariOnIOS()) {
            const byteCharacters = atob(response.data.data.File);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: "application/pdf" });

            // Crear un objeto URL para el blob
            const url = window.URL.createObjectURL(blob);

            // Crear un enlace y simular un clic para descargar el archivo
            const downloadLink = document.createElement("a");
            downloadLink.href = url;
            downloadLink.setAttribute(
              "download",
              `resultado_${resultado.idLaboratory}.pdf`
            );
            document.body.appendChild(downloadLink);
            downloadLink.download = `resultado_${resultado.idLaboratory}.pdf`;
            downloadLink.click();

            // Liberar el objeto URL creado
            window.URL.revokeObjectURL(url);
          } else {

          const linkSource = `data:application/pdf;base64,${response.data.data.File}`;
          const downloadLink = document.createElement("a");
          const fileName = `resultado_${resultado.idLaboratory}.pdf`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          }
        })
        .catch((error) => {});
    }
  };

  const verResultado = (resultado) => {
    var win = window.open(resultado.links.urlViewer, "_blank");
    win.focus();
  };

  const enviarEmail = (resultado) => {
    myAxios({
      url: resultado.links.urlSendEmail,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
    })
      .then((response) => {
        if (!response.error) {
          emailToggleSuccess.onToggle();
        } else {
          emailToggleError.onToggle();
        }
      })
      .catch((error) => {});
  };

  const Desktop = () => {
    return (
      <>
        {/* SEPARADOR */}
        <div
          className="separador-table-red-result"
          id="separator-top-results"
        ></div>
        {/* TABLA */}
        <div className="row mt-3">
          <div className="col-lg-12">
            <div className="table-responsive">
              {!loader ? (
                <table className="table table-hb" id="tableResults">
                  <thead>
                    <tr>
                      {results.length > 0 && (
                        <>
                          <th>Fecha</th>
                          <th>Estudio</th>
                          <th>Servicio</th>
                          <th>Acciones</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {results.length > 0 ? (
                      results.map((result) => {
                        return (
                          <tr key={result.idLaboratory}>
                            <td>{result.dateDisplay}</td>
                            <td>{result.description}</td>
                            <td>{result.service}</td>
                            <td>
                              {result.links.urlViewer ? (
                                <img
                                  className="pointer"
                                  onClick={() => verResultado(result)}
                                  src={ViewIcon}
                                  alt="..."
                                ></img>
                              ) : (
                                <img
                                  className="i-25-result"
                                  src={ViewIcon}
                                  alt="..."
                                  style={{ opacity: "50%" }}
                                ></img>
                              )}
                              {result.links.urlDownload ? (
                                <img
                                  className="pointer"
                                  onClick={() => descargar(result)}
                                  src={DownloadIcon}
                                  alt="..."
                                ></img>
                              ) : (
                                <img
                                  className="i-25-result"
                                  src={DownloadIcon}
                                  alt="..."
                                  style={{ opacity: "50%" }}
                                ></img>
                              )}
                              {result.links.urlSendEmail ? (
                                <img
                                  className="pointer"
                                  onClick={() => enviarEmail(result)}
                                  src={MailIcon}
                                  alt="..."
                                ></img>
                              ) : (
                                <img
                                  className=""
                                  src={MailIcon}
                                  alt="..."
                                  style={{ opacity: "50%" }}
                                ></img>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <td
                        colSpan="5"
                        style={{
                          textAlign: "center",
                          paddingBottom: "15px",
                          paddingTop: "15px",
                        }}
                      >
                        <p
                          className="section_hb_title"
                          style={{
                            fontSize: "16px",
                          }}
                        >
                          No se encontraron resultados con esas opciones de
                          búsqueda.
                          <br /> Intente con otros parámetros, o contáctese con
                          nuestro equipo de{" "}
                          <a
                            href="mailto:soportewebhb@hbritanico.com.ar"
                            className="section_hb_title"
                            style={{
                              fontSize: "16px",
                              textDecoration: "underline",
                            }}
                          >
                            Soporte
                          </a>
                          .
                        </p>
                      </td>
                    )}
                  </tbody>
                </table>
              ) : (
                <div className="text-center" style={{ paddingBottom: "15px" }}>
                  <span className="spinner-grow spinner-grow-sm"></span>
                  <span className="spinner-grow spinner-grow-sm"></span>
                  <span className="spinner-grow spinner-grow-sm"></span>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* SEPARADOR */}
        <div className="separador-table-red-result"></div>
        {total > 0 ? (
          <div id="paginate">
            {(offset > 0) & !loader ? (
              <div className="btn btn-red-without-border" onClick={anterior}>
                Anterior
              </div>
            ) : (
              <></>
            )}
            {!loader ? (
              <span className="number-hb-paginate">
                <a href="#">{offset / 5 + 1}</a>
              </span>
            ) : (
              <></>
            )}
            {(offset + results.length < total) & !loader ? (
              <div className="btn btn-red-without-border" onClick={siguiente}>
                Siguiente
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const Mobile = () => {
    return (
      <>
        <div
          className="separador-table-red-result"
          style={{ marginBottom: "20px" }}
        ></div>

        {!loader ? (
          <div>
            {results.length > 0 ? (
              <div className="mt-2">
                {results.map((result) => (
                  <TarjetaResultadosComponent
                    key={result.idLaboratory}
                    result={result}
                    estudio={result.description}
                    eventPreview={verResultado}
                    eventDownload={descargar}
                    eventMail={enviarEmail}
                  />
                ))}
              </div>
            ) : (
              <p
                className="section_hb_title"
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  paddingBottom: "15px",
                  paddingTop: "15px",
                }}
              >
                No se encontraron resultados con esas opciones de búsqueda.
                <br /> Intente con otros parámetros, o contáctese con nuestro
                equipo de{" "}
                <a
                  href="mailto:soportewebhb@hbritanico.com.ar"
                  className="section_hb_title"
                  style={{
                    fontSize: "16px",
                    textDecoration: "underline",
                  }}
                >
                  Soporte
                </a>
                .
              </p>
            )}

            {total > 0 ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {!loader ? (
                  <div
                    className="btn btn-red-without-border"
                    onClick={offset === 0 ? null : anterior}
                    style={
                      offset === 0
                        ? { visibility: "hidden" }
                        : { visibility: "visible" }
                    }
                  >
                    Anterior
                  </div>
                ) : (
                  <></>
                )}
                {!loader ? (
                  <span className="number-hb-paginate">
                    <a href="#">
                      {offset / 5 + 1} de {Math.ceil(total / 5)}
                    </a>
                  </span>
                ) : (
                  <></>
                )}
                {!loader ? (
                  <div
                    className="btn btn-red-without-border"
                    onClick={
                      offset / 5 + 1 === Math.ceil(total / 5) ? null : siguiente
                    }
                    style={
                      offset / 5 + 1 === Math.ceil(total / 5)
                        ? { visibility: "hidden" }
                        : { visibility: "visible" }
                    }
                  >
                    Siguiente
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div
            className="text-center"
            style={{ paddingBottom: "15px", marginTop: "50px" }}
          >
            <span className="spinner-grow spinner-grow-sm"></span>
            <span className="spinner-grow spinner-grow-sm"></span>
            <span className="spinner-grow spinner-grow-sm"></span>
          </div>
        )}
      </>
    );
  };

  return (
    <section className=" " ref={refBusqueda}>
      <EmailComponentSuccess
        visible={emailToggleSuccess.toggle}
        success={true}
        onToggle={emailToggleSuccess.onToggle}
      />

      <EmailComponentError
        visible={emailToggleError.toggle}
        success={false}
        onToggle={emailToggleError.onToggle}
      />

      <div className="container p-4" id="container-resultados">
        {/* TITULO */}
        <div className="row container-label-results mb-3">
          <div className="col-lg-5">
            <h2 className="section_hb_title title-results">
              <b className="section_hb_title">Resultados de estudios</b>
            </h2>
          </div>

          <div className="col-lg-1"></div>
        </div>
        {/* SEPARADOR */}
        {/* <div className="separador-result"></div> */}

        <div className="row">
          <div className="item-1" id="findUserName">
            <div className="input-icons me-3" style={{ position: "relative" }}>
              <FontAwesomeIcon
                icon={faSearch}
                className="custom-icon-results"
              />
              <input
                className="input-field-results"
                type="text"
                placeholder="Buscar"
                onChange={searchData}
              />
            </div>
            <div>
              <div
                id="filterServices"
                className=""
                style={{ position: "relative" }}
              >
                <CustomSelect
                  width="200px"
                  defaultText={serviceSelected}
                  optionsList={estudiosList}
                  type={CUSTOM_SELECT_COLOR_RED}
                  name="services"
                  onChange={changeService}
                  onClick={clickService}
                  fontFamily="Roboto-Medium"
                  close={closeService}
                />
              </div>
            </div>
          </div>

          <div className="item-2 col-years-results">
            <div id="title-study-results" style={{ width: "112px" }}>
              <span className="año-estudio">Año del estudio</span>
            </div>
            <div style={{ position: "relative", width: "111px" }}>
              <CustomSelect
                close={closeAno}
                defaultText={year}
                optionsList={yearsList}
                type={CUSTOM_SELECT_COLOR_WHITE}
                onChange={onChangeYear}
                onClick={clickYear}
                width="111px"
                name="year"
                paddingTop="1rem"
                fontFamily="Roboto-Regular"
              />
            </div>
          </div>

          <div className="item-3" style={{ marginBottom: "0rem" }}>
            <div
              id="dropdown-name"
              style={{ position: "relative", maxWidth: "250px" }}
            >
              <CustomSelect
                defaultText={familyItem}
                optionsList={familyGroupList ?? [{ id: "", name: "" }]}
                type={CUSTOM_SELECT_COLOR_BLUE}
                width="100%"
                name="family"
                onChange={changeidSap}
                onClick={clickIdSap}
                fontFamily="Roboto-Regular"
                close={closeFamily}
              />
            </div>
          </div>
        </div>

        <div class="d-block d-md-none w-100" style={{ marginTop: "15px" }}>
          <Mobile />
        </div>

        {/* Componente para pantallas medianas y grandes (oculto en dispositivos móviles) */}
        <div class="d-none d-md-block w-100">
          <Desktop />
        </div>
      </div>
    </section>
  );
};

export default ResultsComponent;
