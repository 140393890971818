import { createContext, useState, useEffect } from "react";
import axios from "axios";

import { deleteAllCookies } from "../../utils/cookies";

// memory space created
export const UserGlobalContextMemorySpace = createContext();

export const UserGlobalContext = ({children}) =>{


    const [mainLoader, setMainLoader] = useState(true);
    const [sitePSHB, setSitePSHB] = useState(null); 
    const [userPSHB, setUserPSHB] = useState(null);
    const [user, setUser] = useState(null);
    const [originalUser, setOriginalUser] = useState(null);
    const [familiarGroup, setFamiliarGroup] = useState(null);
    const [familiarGroupToken, setFamiliarGroupToken] = useState(null);
    const [familiarGroupComponent, setFamiliarGroupComponent] = useState(null);
    const [clearContext, setClearContext] = useState(false);
    const [reintegro,setReintegro]=useState();
    const [socialMediaModal, setSocialMediaModal] = useState(false);
    const [socialMediaData, setSocialMediaData] = useState({});
    const [openHelper,setOpenHelper]=useState(false);
    const [footerConfiguration,setFooterConfiguration] = useState({})

    const logout = () =>{
        setSitePSHB(null);
        setUserPSHB(null);
        setUser(null);
        setOriginalUser(null);
        setFamiliarGroup(null);
        setFamiliarGroupToken(null);
        setFamiliarGroupComponent(null);
        setClearContext(false);
        setReintegro();
        localStorage.clear();
        deleteAllCookies();
    }

    const getFooterConfiguration=async ()=>{
        const footer= await axios({
            url: process.env.REACT_APP_STRAPI_QA+"/footers?estado=PU",
            method: 'get',                
        });    

        setFooterConfiguration(footer.data[0])
    }

    useEffect(()=>{
        //Mount: 

        let sessionLocal = localStorage.getItem("enc");

        getFooterConfiguration();
        if(sessionLocal){



            axios({
                url: process.env.REACT_APP_STRAPI_QA+process.env.REACT_APP_STRAPI_QA_ENDPOINT_ENCSESSION,
                method: 'POST',
                data: {enc: sessionLocal}
            })
            .then((res)=>{
                // console.log("res.data.result", res.data.result)

                if(res.data.result.responseCode == 200){

                    setSitePSHB(false);
                    setUserPSHB(false);
                    setUser({user: res.data.result});
                    setOriginalUser({user: res.data.result});
                    setMainLoader(false)

                }else{
                    setMainLoader(false)
                    localStorage.clear();
                }


            }).catch((err)=>{
                console.log("err", err)
                localStorage.clear();
                setMainLoader(false);
            })
        }else{
            setMainLoader(false)
        }

        return () =>{
        //Unmount
    
        }
      }, [clearContext])


    return(
        <UserGlobalContextMemorySpace.Provider 
            value={
                {
                    mainLoader, setMainLoader,
                    socialMediaData, setSocialMediaData,
                    socialMediaModal, setSocialMediaModal,
                    sitePSHB, setSitePSHB,
                    userPSHB, setUserPSHB,
                    user, setUser,
                    familiarGroup, setFamiliarGroup,
                    familiarGroupToken, setFamiliarGroupToken,
                    familiarGroupComponent, setFamiliarGroupComponent,
                    clearContext, setClearContext,
                    reintegro,setReintegro,
                    logout,
                    openHelper,
                    setOpenHelper,
                    footerConfiguration,
                    setFooterConfiguration,
                    originalUser, setOriginalUser
                }}>
            {children}
        </UserGlobalContextMemorySpace.Provider>
    );
}