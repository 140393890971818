import React from "react";
import { Button, Modal } from "react-bootstrap";
import CheckGreenIcon from "../../../../assets/img/check-green.png";
import { fetchStrapi, fetchStrapiPSHB } from "../../../../utils/fetch";

import "./informacion.scss";
export const InformacionComponent = ({ visible = false, onToggle }) => {
  React.useEffect(() => {
    getData();
  }, []);

  const [contenido, setContenido] = React.useState(null);
  const [loader, setLoader] = React.useState(true);

  const getData = async () => {
    try {
      const { data } = await fetchStrapiPSHB(
        `ayudas?idEmpresa=2&codigo=facturacionPS`,
        "GET"
      );
      setContenido(data[0]);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };
  return (
    <Modal
      size={"xl"}
      aria-labelledby="contained-modal-title-vcenter"
      show={visible}
      centered
      onHide={onToggle}
    >
      <Modal.Header closeButton className="header-modal-pshb">
        <Modal.Title className="titulo-facturas-Fac">
          {contenido?.titulo}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="barra-politica-proteccion-pshb" />

        {contenido?.preguntas?.map((pregunta, index) => (
          <div>
            <div className="preguntas-credencial">
              <img className="i-25" src={CheckGreenIcon} alt="..."></img>
              <div className="question-Fac">{pregunta?.descripcionCorta}</div>
            </div>
            {pregunta?.respuestas?.map((respuesta, index1) => (
              <div className="">
                <div className="answer-credencial">
                  {/* {respuesta?.descripcion}{" "} */}
                  {respuesta.descripcion.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
      </Modal.Body>
      {/* <Modal.Footer>                
                <Button className='btn2-color3-r' variant="primary" onClick={()=>onToggle()}>Cerrar</Button>
            </Modal.Footer> */}
    </Modal>
  );
};
