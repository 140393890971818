import axios from "axios";


export const FetchFunction = async (method, endpoint, token, body) => {
    try {
        const { data } = await axios({
            method: method,
            url: endpoint,
            headers: {'Authorization': 'Bearer '+ token},
            data: body
        })
        return data
    } catch (err) {
        return err;
    }
};