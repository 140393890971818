import { Col, Row } from "react-bootstrap";
import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import UserSidebar from "../../../components/common/user-sidebar";
import { DatosCoberturaComponent } from "../../../components/common/perfil/dato-cobertura";
import DigitalCredentialComponentPSHB from "../../../components/pshb/digital-credential";

const CredencialDigitalPSHB = () => {

  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <DigitalCredentialComponentPSHB> </DigitalCredentialComponentPSHB>        
      </div>
    </div>
  );
};

export default CredencialDigitalPSHB;
