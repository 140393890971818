import "./results.scss";
import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import ResultsComponent from "../../../components/hb/results";
import { PopupValidateSap } from "../../../components/common/popup-validate-sap";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import useValidProfile from "../../../hooks/useValidProfile";

const Results = () => {
  const { user } = useContext(UserGlobalContextMemorySpace);
  const { showPopup } = useValidProfile(user);

  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      {showPopup ? (
        <PopupValidateSap message="Para ver resultados" visible={true} />
      ) : (
        <ResultsComponent> </ResultsComponent>
      )}
    </div>
  );
};

export default Results;
