import CertificadosDeAsistenciaComponent from "../../../../components/hb/atencion-al-paciente/certificados-asistencia";
import UserSidebar from "../../../../components/common/user-sidebar";
import React, { useContext, useState, useRef, useLayoutEffect } from "react";

const CertificadoAsistencia = () => {

  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <CertificadosDeAsistenciaComponent />
    </div>
  );
};

export default CertificadoAsistencia;
