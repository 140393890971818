import React from "react";
import { Button, Modal } from "react-bootstrap";
import CheckGreenIcon from "../../../../assets/img/check-green.png";
import { fetchExternal } from "../../../../utils/fetch";
import dompurify from "dompurify";

import "./informacion.scss";
export const InformacionComponent = ({ visible = false, onToggle }) => {
  React.useEffect(() => {
    getData();
  }, []);

  const [contenido, setContenido] = React.useState(null);
  const [loader, setLoader] = React.useState(true);
  const sanitizer = dompurify.sanitize;

  const getData = async () => {
    try {
      const { data } = await fetchExternal(
        `${process.env.REACT_APP_STRAPI_QA}/ayudas?idEmpresa=1&codigo=contrasenaHB`,
        "GET"
      );
      setContenido(data[0]);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };
  return (
    <Modal
      size={"xl"}
      aria-labelledby="contained-modal-title-vcenter"
      show={visible}
      centered
      onHide={onToggle}
    >
      <Modal.Header closeButton className="header-modal-pshb">
        <Modal.Title className="titulo-facturas-Fac">
          {contenido?.titulo}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="subtitulo-credencial contrasenaSubTit">
          <span className="subtitulo">{contenido?.descripcionCorta}</span>
        </div>
        {/* <div className="politica-proteccion-pshb" /> */}
        {/* <div
          style={{ marginTop: "20px" }}
          className="barra-politica-proteccion-pshb barraMargin"
        /> */}
        {/* <div className="tutorial-credencial">
          <img
            className="tutorial-credencial-dos"
            src={contenido?.multimedia?.url}
            alt="..."
          ></img>
        </div> */}

        {contenido?.multimedia?.url != null ? (
          <div
            className="tutorial-credencial"
            style={{ paddingRight: "20px", paddingLeft: "20px" }}
          >
            <img
              className="imagen-multimedia"
              src={contenido?.multimedia?.url}
              alt="..."
            ></img>
          </div>
        ) : (
          <></>
        )}

        <hr
          className="barra-politica-proteccion-pshb barraMargin"
          style={{
            border: "3px solid red",
            opacity: "20",
          }}
        />

        <div className="politica-proteccion">
          {contenido?.preguntas?.map((pregunta, index) => (
            <div className="section-information mb-70">
              <div className="circulo">{index + 1} </div>
              <div className="answer">{pregunta.descripcionCorta}</div>
              {pregunta?.respuestasenriquecidas?.map((respuesta, index1) => (
                <div className="" dangerouslySetInnerHTML={{__html: sanitizer(respuesta?.descripcion)}}/>                                                    
              ))}
            </div>
          ))}
        </div>
        {/* {contenido?.preguntas?.map((pregunta, index) => (
          <div>
            <div className="preguntas-credencial">
              <img className="i-25" src={CheckGreenIcon} alt="..."></img>
              <div className="question-Fac">{pregunta?.descripcionCorta}</div>
            </div>
            {pregunta?.respuestas?.map((respuesta, index1) => (
              <div className="">
                <div className="answer-credencial">
                  {respuesta.descripcion.split("\n").map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))} */}
      </Modal.Body>
      {/* <Modal.Footer>                
                <Button className='btn2-color3-r' variant="primary" onClick={()=>onToggle()}>Cerrar</Button>
            </Modal.Footer> */}
    </Modal>
  );
};
