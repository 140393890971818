import React, { useContext, useState, useRef, useLayoutEffect } from "react";
import CommonRecuperoContrasena from "../../../components/common/password-change";
import UserSidebar from "../../../components/common/user-sidebar";
import AyudaComponent from "../../../components/hb/ayuda";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import style from "./style.scss";

const CambiarContrasenaHB = () => {

  const ref = useRef(null);
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);

  return (
    <div className="d-flex help-page" ref={ref}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div className="recupero-contrasena h-100 d-flex justify-content-center align-items-center">
          <CommonRecuperoContrasena></CommonRecuperoContrasena>
        </div>
      </div>
    </div>
  );
};

export default CambiarContrasenaHB;
