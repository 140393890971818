import React, {useEffect} from 'react';
import LoginHB from '../../../pages/hb/login';
import { useNavigate } from 'react-router-dom';

const CommonLogin = (props) => {

    const navigate = useNavigate();

    useEffect(()=>{

        //Mount: 
        if(props.redirect){
            navigate("/")
        }

        return () =>{
        //Unmount
    
        }
    }, [])


    return (
        <>
            <LoginHB pshb={false}></LoginHB>
        </>
    );
}

export default CommonLogin;
