import React from "react";

const useValidProfile = (user) => {
  const [showPopup, setSetshowPopup] = React.useState(false);

  React.useLayoutEffect(() => {
    if (!user) return;
    const customer = user.user.data.Customers;
    customer.socialSecurity = customer.socialSecurity?.trim();
    customer.socialSecurityNumber = customer.socialSecurityNumber?.trim();
    customer.socialSecurityPlan = customer.socialSecurityPlan?.trim();

    // Saco el usuario particular de la validacion
    if (customer.socialSecurity == "PARTICULAR") {
      return;
    }

    // Muestro popup a usuario afiliado con datos faltantes
    const isInvalid =
      !customer.socialSecurity ||
      !customer.socialSecurityNumber ||
      !customer.socialSecurityPlan;

    if (isInvalid) {
      setSetshowPopup(true);
      return;
    }
  }, []);

  return { showPopup };
};

export default useValidProfile;
