import React from "react";
import { Button, Modal } from "react-bootstrap";
import Punto2 from "../../../../assets/img/instructivo/Punto_2.png";
import Punto3 from "../../../../assets/img/instructivo/Punto_3.png";
import Punto4 from "../../../../assets/img/instructivo/Punto_4.png";
import Punto5a from "../../../../assets/img/instructivo/Punto_5-A.png";
import { fetchStrapi } from "../../../../utils/fetch";
import CheckGreenIcon from "../../../../assets/img/check-green.png";

import "./informacion.scss";
export const InformacionComponent = ({ visible = false, onToggle }) => {
  React.useEffect(() => {
    getData();
  }, []);

  const [contenido, setContenido] = React.useState(null);
  const [loader, setLoader] = React.useState(true);

  const getData = async () => {
    try {
      const { data } = await fetchStrapi(
        `ayudas?idEmpresa=2&codigo=agregarFamiliarPS`,
        "GET"
      );
      setContenido(data[0]);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  return (
    <Modal
      size={"xl"}
      aria-labelledby="contained-modal-title-vcenter"
      show={visible}
      centered
      onHide={onToggle}
    >
      <Modal.Header closeButton className="custom-header">
        <div className="header-familygroup-information">
          <span className="titulo-facturas">{contenido?.titulo}</span>

          <p>
            <span className="question">{contenido?.descripcionCorta}</span>
          </p>
          <div className="barra-informacion pl-20 w-100" />
        </div>
      </Modal.Header>

      <Modal.Body>
        <div className="politica-proteccion">
          {contenido?.preguntas?.map((pregunta, index) => (
            <div className="section-information mb-70">
              {/* <div className="circulo">{index + 1} SSSSSSSSSS</div> */}
              <div className="preguntas-credencial">
                <img className="i-25" src={CheckGreenIcon} alt="..."></img>
                <div className="answer">{pregunta.descripcionCorta}</div>
                {/* <img className="i-25" src={CheckGreenIcon} alt="..."></img>
                <div className="question-Fac">{pregunta?.titulo}</div> */}
              </div>
              {pregunta?.respuestas?.map((respuesta, index1) => (
                <div className="">
                  {respuesta?.multimedia?.url && (
                    <img
                      className="imagen-multimedia"
                      src={respuesta?.multimedia?.url}
                      alt=""
                    ></img>
                  )}
                  <div className="answer">
                    {/* {respuesta.descripcion}{" "} */}
                    {respuesta.descripcion.split("\n").map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </Modal.Body>
      {/* <Modal.Footer>                
                <Button className='btn2-color3-r' variant="primary" onClick={()=>onToggle()}>Cerrar</Button>
            </Modal.Footer> */}
    </Modal>
  );
};
