import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Row, Col } from "react-bootstrap";
import SuccessIcon from "../../../assets/img/admiracion-abajo.svg";

export const PopupValidateSap = ({ visible = false, message = false }) => {
  const navigate = useNavigate();
  return (
    <Modal
      size={"md"}
      aria-labelledby="contained-modal-title-vcenter"
      show={visible}
      centered
    >
      <Modal.Body
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={SuccessIcon}
          alt="Message"
          style={{ width: "50px", marginBottom: 10 }}
        />
        <div className="header-familygroup-information mt-2">
          <span
            className="titulo-eliminar-familiar text-center"
            style={{ fontSize: 23 }}
          >
            Por favor, actualice sus datos de cobertura (cobertura, plan y N° de
            afiliado) <br />
            haciendo clic{" "}
            <a
              onClick={() =>
                navigate("/perfil", {
                  state: { popup: true },
                })
              }
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              aquí
            </a>
          </span>
        </div>

        <div className="politica-proteccion-pshb">
          <div className="section-edit-information">
            <section>
              <Row>
                <Col md={12}>
                  <div className="row mt-4">
                    <div
                      className="col-md-12 family-add-buttons"
                      style={{ paddingBottom: "10px" }}
                    >
                      <div
                        className="btn btn-hb-primary-without-border"
                        onClick={() => navigate(-1)}
                      >
                        Volver
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </section>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
