import React, {useContext, useState} from 'react';
import logoHB from '../../../assets/img/hb/logo.png';
import logoPSHB from '../../../assets/img/pshb/logo.jpg';
import { useNavigate  } from 'react-router-dom';
import { ImCancelCircle } from 'react-icons/im';


const CommonNotFound = (props) => {

    const navigate = useNavigate();

    const volver = () =>{
        navigate("/")
    }

  return (
    <div className="d-flex align-items-center justify-content-center" style={{height: props.loggedIn ? "60vh" : "100vh"}}>
        <div className="">
            <div className='d-flex flex-column align-items-center justify-content-center'>
                {props.loggedIn ? "" : <img className='img-logo' src={logoHB}></img>}
                <div className='br'></div>
                <ImCancelCircle size='40px' style={{color: "#224d7c"}}/>
                <div className='br'></div>
                <h5 className='default-fontColor' style={{textAlign: "center"}}>No pudimos encontrar la página que estás buscando.</h5>
                <div className='br'></div>
                <button onClick={volver} className='button-continuar shadow-blue' type="submit" value="Continuar">Volver</button>
            </div>
        </div>
    </div>
  );
}

export default CommonNotFound;
