import React, { useContext, useState } from "react";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import style from "./style.scss";
import bgImageLogin from './../../../assets/img/hb/image-login.jpg'
import bgImageLoginPSHB from './../../../assets/img/hb/test2.jpg'
import logoHB from "../../../assets/img/hb/logo.png";
import logoPSHB from "../../../assets/img/pshb/logo.jpg";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { emailValidator } from "../../../utils/hooks/regex";
import { myAxios } from "../../../utils/fetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import useProgressiveImage from "../../../hooks/useProgressiveImage";

const CommonRegisterConfirmation = (props) => {
  const [loader, setLoader] = useState(false);
  const [newPinButton, setNewPinButton] = useState(false);
  const [disabledButton, setDisabledButton] = useState(false);
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [checkMail, setCheckMail] = useState("");
  const [hideButton, setHideButton] = useState(false);

  const loadedBgImage = useProgressiveImage(bgImageLogin)


  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams({});
  const token = searchParams.get("token");

  const navigate = useNavigate();

  const SwalMessage = (icon, title, text, confirmButtonText) => {
    Swal.fire({
      icon: icon,
      title: title,
      text: text,
      color: "#224d7c",
      confirmButtonColor: "#224d7c",
      confirmButtonText: confirmButtonText,
    });
  };

  const handlerRegisterConfirmation = (event) => {
    event.preventDefault();
    setErrorMsg("");

    let body = {
      email: props.emailParam,
      pin: props.pin,
      pshb: false,
      password: password,
    };

    let specialCharacterValidation = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    let upperCaseValidation = /[A-Z]/;

    if (
      specialCharacterValidation.test(password) &&
      upperCaseValidation.test(password) &&
      password.length > 7
    ) {
      if (newPinButton) {
        setLoader(true);
        axios({
          url:
            process.env.REACT_APP_STRAPI_QA +
            process.env
              .REACT_APP_STRAPI_QA_ENDPOINT_REGISTER_VERIFICATION_RESETPIN,
          method: "POST",
          data: { email: props.emailParam, pshb: false },
        })
          .then((res) => {
            if (res.data.responseCode && res.data.responseCode == 200) {
              setDisabledButton(true);
              setLoader(false);
              setCheckMail("Enlace de confirmación enviado.");
              setHideButton(true);
              Swal.fire({
                allowOutsideClick: false,
                icon: "success",
                title:
                  '<h5 style="color: #224d7c">Generación de enlace de confirmación realizado con éxito.</h5>',
                text: res.data.message,
                color: "#224d7c",
                confirmButtonColor: "#224d7c",
                confirmButtonText: "Continuar",
              });
            } else if (res.data.responseCode && res.data.responseCode == 400) {
              setLoader(false);
              SwalMessage(
                "warning",
                '<h5 style="color: #224d7c"></h5>',
                res.data.message,
                "Continuar"
              );
            } else {
              setLoader(false);
              SwalMessage(
                "warning",
                '<h5 style="color: #224d7c"></h5>',
                "Detectamos un error en la solicitud de generación del enlace de confirmación, por favor intenta mas tarde o comunicate con nuestro equipo de soporte.",
                "Continuar"
              );
            }
          })
          .catch((error) => {
            setLoader(false);
            SwalMessage(
              "warning",
              '<h5 style="color: #224d7c"></h5>',
              "Detectamos un error en la solicitud de generación del enlace de confirmación, por favor intenta mas tarde o comunicate con nuestro equipo de soporte.",
              "Continuar"
            );
          });
      } else {
        if (password == "" || password2 == "") {
          setErrorMsg(
            "* Por favor ingresa las dos contraseñas para confirmar tu cuenta."
          );
        } else if (password !== password2) {
          setErrorMsg("* Las contraseñas ingresadas no coinciden.");
        } else if (emailValidator(props.emailParam)) {
          setErrorMsg("* Email Invalido.");
        } else {
          setLoader(true);
          axios({
            url:
              process.env.REACT_APP_STRAPI_QA +
              process.env.REACT_APP_STRAPI_QA_ENDPOINT_REGISTER_VERIFICATION,
            method: "POST",
            data: body,
          })
            .then((res) => {
              if (res.data.responseCode && res.data.responseCode == 200) {
                setLoader(false);
                Swal.fire({
                  allowOutsideClick: false,
                  icon: "success",
                  title:
                    '<h5 style="color: #224d7c">Cuenta Confirmada con éxito</h5>',
                  text: res.data.message
                    ? res.data.message
                    : "Ya puedes iniciar sesión en tu portal HB Online.",
                  color: "#224d7c",
                  confirmButtonColor: "#224d7c",
                  confirmButtonText: "Continuar",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/");
                  }
                });
              } else if (
                res.data.responseCode &&
                res.data.responseCode == 400
              ) {
                if (
                  res.data.data.type &&
                  res.data.data.type == "InvalidPincode"
                ) {
                  setLoader(false);
                  setNewPinButton(true);
                  SwalMessage(
                    "warning",
                    '<h5 style="color: #224d7c"></h5>',
                    res.data.message,
                    "Continuar"
                  );
                } else {
                  setLoader(false);
                  SwalMessage(
                    "warning",
                    '<h5 style="color: #224d7c"></h5>',
                    res.data.message,
                    "Continuar"
                  );
                }
              } else {
                setLoader(false);
                SwalMessage(
                  "warning",
                  '<h5 style="color: #224d7c"></h5>',
                  "Detectamos un error en el proceso de confirmación de cuenta; por favor comunicate con nuestro equipo de soporte.",
                  "Continuar"
                );
              }
            })
            .catch((error) => {
              setLoader(false);
              SwalMessage(
                "warning",
                '<h5 style="color: #224d7c"></h5>',
                "Detectamos un error en el proceso de confirmación de cuenta. Por favor comunicate con nuestro equipo de soporte.",
                "Continuar"
              );
            });
        }
      }
    } else {
      setErrorMsg(
        "* La contraseña debe contener minimo 8 caracteres, con al menos una mayuscula y un caracter especial."
      );
    }
  };

  return (
    <form onSubmit={handlerRegisterConfirmation}>
      <div className="d-flex align-items-center" style={{ height: "100vh" }}>
        <div className="div-login">
          <div className="d-flex flex-column align-items-center">
            <img
              className="img-logo"
              src={logoHB}
            ></img>
            <div className="br"></div>
            <h5 className="default-fontColor">
              {checkMail !== ""
                ? checkMail
                : newPinButton
                ? "Solicita un Nuevo enlace Confirmación"
                : "Confirmar Cuenta"}
            </h5>
            <div className="br"></div>
            <input
              value={props.emailParam}
              className="input-login shadow-blue"
              disabled
              placeholder="E-mail"
            ></input>
            <div className="br"></div>

            {newPinButton ? (
              <>
                {hideButton ? (
                  <></>
                ) : (
                  <>
                    {loader ? (
                      <button
                        disabled={true}
                        type="submit"
                        className="button-loader shadow-blue"
                      >
                        <span className="spinner-grow spinner-grow-sm"></span>
                        <span className="spinner-grow spinner-grow-sm"></span>
                        <span className="spinner-grow spinner-grow-sm"></span>
                      </button>
                    ) : (
                      <input
                        disabled={disabledButton}
                        className="button-continuar shadow-blue"
                        type="submit"
                        value="Generar Enlace"
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <div
                  className="input-login shadow-blue"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <input
                    style={{ width: "94%", height: "98%" }}
                    className="custom-input-login"
                    maxLength={20}
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    //   type="password"
                    type={passwordShown ? "text" : "password"}
                    // className="input-login shadow-blue"
                    placeholder="Contraseña"
                  ></input>
                  <FontAwesomeIcon
                    style={{ right: "7px", position: "absolute" }}
                    icon={passwordShown ? faEyeSlash : faEye}
                    onClick={() => setPasswordShown((previus) => !previus)}
                  />
                </div>
                <div className="br"></div>
                <div
                  className="input-login shadow-blue"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <input
                    style={{ width: "94%", height: "98%" }}
                    className="custom-input-login"
                    maxLength={20}
                    onChange={(e) => setPassword2(e.target.value)}
                    value={password2}
                    //   type="password"
                    type={passwordShown2 ? "text" : "password"}
                    // className="input-login shadow-blue"
                    placeholder="Confirma tu Contraseña"
                  ></input>
                  <FontAwesomeIcon
                    style={{ right: "7px", position: "absolute" }}
                    icon={passwordShown2 ? faEyeSlash : faEye}
                    onClick={() => setPasswordShown2((previus) => !previus)}
                  />
                </div>
                <div className="br"></div>

                {loader ? (
                  <button
                    disabled={true}
                    type="submit"
                    className="button-loader shadow-blue"
                  >
                    <span className="spinner-grow spinner-grow-sm"></span>
                    <span className="spinner-grow spinner-grow-sm"></span>
                    <span className="spinner-grow spinner-grow-sm"></span>
                  </button>
                ) : (
                  <input
                    className="button-continuar shadow-blue"
                    type="submit"
                    value="Continuar"
                  />
                )}
                {errorMsg ? <div className="br"></div> : null}
                <p
                  style={{
                    color: "red",
                    fontWeight: "500",
                    maxWidth: "90%",
                    textAlign: "center",
                  }}
                >
                  {errorMsg}
                </p>
              </>
            )}
            {/* <div className='br'></div> */}
            {checkMail !== "" ? (
              <Link to={"/"} className="link-noStyle">
                Iniciar Sesión
              </Link>
            ) : null}
          </div>
        </div>
        <div
          className={"bg-image"}
          style={{ backgroundImage: `url(${loadedBgImage || ''})` }}
        >
          <div className="bg-image-text">
            <div className="bg-image-text">
              <div className="transbox">
                <>
                  <h1>Bienvenido a mi HB Online</h1>
                  <h5>
                    Solicitá un turno, accedé a tus resultados, gestioná tu
                    grupo familiar y tu perfil
                  </h5>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CommonRegisterConfirmation;
