import React, { useContext } from "react";
import "./logout.scss";
import { faCircleUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import LogosComponent from "../../common/logos/logos";
import CommonFooter from "../../common/footer";
import logo from "../../../assets/img/logos/logo-hospital.png";

const LogoutComponent = () => {
  const { logout } = useContext(UserGlobalContextMemorySpace);
  const navigate = useNavigate();

  setTimeout(() => {
    logout();
    navigate("/");
  }, 3000);

  const handleCierreSesion = () => {
    logout();
    navigate("/");
  };

  return (
    <>
      <section
        className="d-flex align-items-center justify-content-center"
        style={{ height: "calc(100vh - 100px)" }}
      >
        <div className="d-flex justify-content-center align-items-center flex-column h-logout-custom">
          <img className="img-logo" src={logo}></img>
          <div className="br"></div>
          <FontAwesomeIcon icon={faCircleUser} size="5x" color="#05294F" />
          <span id="title-logout-HB" className="mt-2 mb-2">
            <b>Tu sesión finalizó</b>
          </span>
        </div>
      </section>
    </>
  );
};

export default LogoutComponent;
