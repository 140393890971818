import { useState, useContext, useEffect } from "react";
import { myAxios } from "../../../../../utils/fetch";
import { UserGlobalContextMemorySpace } from "../../../../../contexts/user/userGlobalContext";

const useFamilyGroup = () => {
  const { user } = useContext(UserGlobalContextMemorySpace);
  const [familyGroupList, setFamilyGroupList] = useState(null);

  useEffect(() => {
    myAxios({
      url:
        process.env.REACT_APP_GATEWAY_QA +
        "/customers/api/v1/familiargroup/getfamiliargroupbyid",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        USERTOKEN: user.user.data.Token,
        Authorization: `Bearer ${user.user.data.appToken}`,
      },
      data: {
        idCustomer: user.user.data.Customers.idCustomer,
      },
    })
      .then((response) => {
        const familyGroup = response.data.data.FamilyGroup.map((family) => {
          return { id: family.idSap ?? "", name: family.fullName };
        });
        var temporalFamilyGroup = [
          {
            id: user.user.data.Customers.idSap,
            name:
              user.user.data.Customers.firstName +
              " " +
              user.user.data.Customers.lastName,
          },
          ...familyGroup,
        ];
        setFamilyGroupList(temporalFamilyGroup);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  return {
    familyGroupList,
  };
};

export default useFamilyGroup;
