/**
 *
 * @param {*} name nombre de la Cookie
 * @returns
 */
export const getCookie = (name) => {
  const cookies = document.cookie.split(";");
  let value = "";

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim().split("=");
    if (cookie[0] === name) {
      value = cookie[1];
      break;
    }
  }

  return value;
};

/**
 *
 * @param {*} name nombre de la Cookie
 * @param {*} value valor de la Cookie
 * @param {*} days dias para expirar de la Cookie
 */
export const saveCookie = (name, value, days) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + days);

  document.cookie = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
  }
};
