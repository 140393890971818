import React, { useContext } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { UserGlobalContextMemorySpace } from "../../../contexts/user/userGlobalContext";
import classNames from "classnames";

//ICONS
import preview from "../../../assets/img/card-icons/previsualizar.svg";
import download from "../../../assets/img/card-icons/descargar.svg";
import mail from "../../../assets/img/card-icons/mail.svg";
import share from "../../../assets/img/card-icons/compartir.svg";

export const TarjetaResultadosComponent = ({
  result,
  eventPreview,
  eventDownload,
  eventMail,
  eventShare
}) => {  
  
  return (
    <div
      className={"tarjeta--activa shadow mt-2"}
    >
      <div
        // className="d-flex justify-content-start align-items-center"        
        style={{display:"flex",flexDirection:"column",gap:"10px"}}
      >
        <div>
            <span className="resultado-etiqueta">Fecha:</span>
            <span className="resultado-valor">{result.dateDisplay}</span>
        </div>
        <div>
            <span className="resultado-etiqueta">Estudio:</span>
            <span className="resultado-valor">{result.description}</span>
        </div>
        <div>
            <span className="resultado-etiqueta">Servicio:</span>
            <span className="resultado-valor">{result.service}</span>
        </div>
      </div>  
      <div class="separador" style={{width:"100%",borderTopWidth:"2px"}}></div>    
      <div className="iconos-acciones-tarjeta">

        {result.links.urlViewer && <img src={preview} alt="Vista previa" onClick={()=>eventPreview(result)}/>}
        {result.links.urlDownload && <img src={download} alt="Descargar" onClick={()=>eventDownload(result)} />}
        {result.links.urlSendEmail && <img src={mail} alt="Email" onClick={()=>eventMail(result)} />}
      </div>
    </div>
  );
};
