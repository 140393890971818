import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import classNames from "classnames";

//ICONS
import download from "../../../../assets/img/card-icons/descargar.svg";
import mail from "../../../../assets/img/card-icons/mail.svg";

export const TarjetaCertificadosComponent = ({
  result,
  eventPreview,
  eventDownload,
  eventMail,
  eventShare
}) => {  
  
  return (
    <div
      className={"tarjeta--activa shadow mt-2"}
    >
      <div
        // className="d-flex justify-content-start align-items-center"        
        style={{display:"flex",flexDirection:"column",gap:"10px"}}
      >
        <div>
            <span className="resultado-etiqueta">Fecha:</span>
            <span className="resultado-valor">{result.date.slice(0,10)}</span>
        </div>
        <div>
            <span className="resultado-etiqueta">Tipo de documento:</span>
            <span className="resultado-valor">{result.type}</span>
        </div>
        <div>
            <span className="resultado-etiqueta">Servicio:</span>
            <span className="resultado-valor">{result.name}</span>
        </div>
      </div>  
      <div class="separador" style={{width:"100%",borderTopWidth:"2px"}}></div>    
      <div className="iconos-acciones-tarjeta">
    
        <img src={download} alt="Descargar" onClick={()=>eventDownload(result)} />
        <img src={mail} alt="Email" onClick={()=>eventMail(result)} />
      </div>
    </div>
  );
};
